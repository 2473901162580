<template>
  <div class="form-container">
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <!-- <b-col cols="12" lg="12"> -->
          <div class="d-flex align-items-center">
            <b-form-group>
              <label>إبحث بﻹسم عن العميل</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="اسم العميل"
              >
                <b-form-input
                  v-model="searchQuery"
                  :state="errors.length > 0 ? false : null"
                  placeholder="search for customer"
                  id="search-select"
                />
                <!-- @keyup="getCustomer()" -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <b-button
                @click="getCustomer()"
                variant="primary"
                class="mb-2"
                style="margin: 45px"
              >
                بحث
              </b-button>
            </b-form-group>
          </div>
          <!-- </b-col> -->
        </b-row>

        <b-row>
          <b-col cols="6" lg="6">
            <b-form-group label="العميل" label-for="fromCustomer">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="العميل"
              >
                <b-form-select
                  id="fromCustomer"
                  v-model="user_id"
                  :options="formattedOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="التاريخ" label-for="preferredDate">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="التاريخ"
              >
                <b-form-datepicker
                  id="preferredDate"
                  v-model="preferredDate"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6" lg="6">
            <b-form-group label="الخدمة" label-for="service">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="الخدمة"
              >
                <b-form-select
                  id="service"
                  v-model="service"
                  :options="servicesOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="الزمن" label-for="preferredTime">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="الزمن"
              >
                <b-form-select
                  id="preferredTime"
                  v-model="preferredTime"
                  :options="periodsOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6" lg="6">
            <b-card class="mb-3">
              <h5>من العنوان</h5>
              <b-form-group label="المدينة" label-for="fromCity">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="1 المدينة"
                >
                  <b-form-select
                    id="fromCity"
                    v-model="fromAddress.district.cityName"
                    :options="citiesOptions"
                    @change="onCityChange('from'), areaOptions()"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- :options="areas[fromAddress.district.cityName] || []" -->

              <b-form-group label="الحي" label-for="fromArea">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="1 الحي"
                >
                  <b-form-select
                    id="fromArea"
                    v-model="fromAddress.district.name"
                    :options="filteredItems"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <leaflet-map :type="'from'" @location-selected="updateLocation" />

              <b-form-group label="احداثيات الموقع" label-for="from location">
                <div class="d-flex align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="1 خط العرض"
                  >
                    <b-form-input
                      id="fromlat"
                      type="text"
                      v-model="fromAddress.lat"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="1 خط الطول"
                    class="mr-2"
                  >
                    <b-form-input
                      id="fromlng"
                      type="text"
                      v-model="fromAddress.lng"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>

              <b-form-group label="الطابق" label-for="fromFloor">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="1 الطابق"
                >
                  <b-form-input
                    id="fromFloor"
                    type="number"
                    v-model="fromAddress.floor"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="ملاحظة" label-for="fromNote">
                <validation-provider
                  #default="{ errors }"
                  rules=""
                  name="ملاحظة"
                >
                  <b-form-textarea
                    id="fromNote"
                    v-model="fromAddress.note"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </b-col>

          <b-col cols="6" lg="6">
            <b-card class="mb-3">
              <h5>إلى العنوان</h5>
              <b-form-group label="المدينة" label-for="toCity">
                <!-- :options="cities" -->
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="المدينة"
                >
                  <b-form-select
                    id="toCity"
                    v-model="toAddress.district.cityName"
                    :options="citiesOptions"
                    @change="onCityChange('to'), areaOptionsTo()"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="الحي" label-for="toArea">
                <!-- :options="areas[toAddress.district.cityName] || []" -->
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="الحي"
                >
                  <b-form-select
                    id="toArea"
                    v-model="toAddress.district.name"
                    :options="filteredItemsTo"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <leaflet-map :type="'to'" @location-selected="updateLocation" />

              <b-form-group label="إحداثيات الموقع" label-for="toFloor">
                <div class="d-flex align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="خط العرض"
                  >
                    <b-form-input
                      id="tolat"
                      type="text"
                      v-model="toAddress.lat"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="خط الطول"
                    class="mr-2"
                  >
                    <b-form-input
                      id="tolng"
                      type="text"
                      v-model="toAddress.lng"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>

              <b-form-group label="الطابق" label-for="toFloor">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="الطابق"
                >
                  <b-form-input
                    id="toFloor"
                    type="number"
                    v-model="toAddress.floor"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="ملاحظة" label-for="toNote">
                <validation-provider
                  #default="{ errors }"
                  rules=""
                  name="ملاحظة"
                >
                  <b-form-textarea
                    id="toNote"
                    v-model="toAddress.note"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6" lg="6">
            <b-card class="mb-3">
              <h5>العناصر</h5>

              <div
                v-for="(item, index) in items"
                :key="index"
                class="d-flex mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="اسم العنصر"
                >
                  <b-form-input
                    v-model="item.name"
                    placeholder="اسم العنصر"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="العدد"
                >
                  <b-form-input
                    v-model="item.amount"
                    type="number"
                    placeholder="العدد"
                    class="ml-2"
                  ></b-form-input>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <b-button
                  @click="removeItem(index)"
                  variant="danger"
                  class="ml-2"
                >
                  Remove
                </b-button>
              </div>

              <b-button @click="addItem" variant="primary">Add Item</b-button>
            </b-card>
          </b-col>

          <b-col cols="6" lg="6">
            <b-card class="mb-3">
              <h5>الصور</h5>

              <!-- <b-form-file v-model="images" multiple></b-form-file>
                <div v-if="images.length" class="mt-3">
                  <img
                    v-for="(image, index) in images"
                    :key="index"
                    :src="image"
                    class="preview-img"
                  />
                </div> -->

              <div>
                <input type="file" multiple @change="handleFileChange" />
                <b-button @click="uploadFiles" variant="primary" class="mt-3"
                  >رفع الصور</b-button
                >
                <ul>
                  <li
                    v-for="(url, index) in uploadedUrls"
                    :key="index"
                    :id="index"
                  >
                    <div class="d-flex align-items-center">
                      <img :src="url" alt="" width="100px" height="150px" />
                      <a
                        class="btn btn-danger"
                        v-if="url"
                        @click="deleteImage(url, index)"
                        >حذف الصورة</a
                      >
                    </div>
                    <!-- <a :href="url" target="_blank">{{ url }}</a> -->
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-form-group label="الملاحظات" label-for="note">
          <validation-provider #default="{ errors }" rules="" name="الملاحظات">
            <b-form-textarea id="note" v-model="note"></b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button type="submit" variant="success">إنشاء الطلب</b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import LeafletMap from "./components/LeafletMap.vue";
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormFile,
  BButton,
  BImg,
  BFormDatepicker,
  BFormTimepicker,
  BInputGroup,
  BRow,
  BCol,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  integer,
  min,
  digits,
  length,
} from "@validations";

import DataService from "./data/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig";

export default {
  components: {
    LeafletMap,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    BButton,
    BImg,
    BFormDatepicker,
    BFormTimepicker,
    BInputGroup,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      confirmed,
      email,
      min,
      integer,
      digits,
      length,

      isLoading: false,
      searchQuery: "",
      options: [],
      optionsServices: [],
      optionsPeriods: [],
      optionsCities: [],
      districtsCities: [],

      // orderData: {
      user_id: "",
      service: "",
      preferredDate: "",
      preferredTime: "",
      note: "",
      // items: [],
      // Start with one item by default
      items: [{ name: "", amount: 0 }],

      images: [],
      // },
      fromAddress: {
        lat: null,
        lng: null,
        floor: 0,
        note: "",
        district: {
          id: null,
          name: "",
          cityName: "",
        },
      },
      toAddress: {
        lat: null,
        lng: null,
        floor: 0,
        note: "",
        district: {
          id: null,
          name: "",
          cityName: "",
        },
      },
      users: ["osman", "abd alrahman", "morad"],
      cities: ["Jeddah", "Riyadh", "Dammam"],
      areas: {
        Jeddah: ["Azizya", "Sulaimania", "Al Hamra"],
        Riyadh: ["Olaya", "Malaz", "Sahara"],
        Dammam: ["Corniche", "Rayyan", "Mubarraz"],
      },

      areasOptions: {},
      selectedItem: "",

      filteredItems: [],
      filteredItemsTo: [],

      files: [], // Selected files
      uploadedUrls: [], // URLs of uploaded images

      image: "",
      file: null,
      progress: 0,
      imageUrl: "",
      message: "",
    };
  },
  computed: {
    formattedOptions() {
      return this.options.map((option) => ({
        value: option.id,
        text: option.name,
      }));
    },

    servicesOptions() {
      return this.optionsServices.map((option) => ({
        value: option.name,
        text: option.name,
      }));
    },

    periodsOptions() {
      return this.optionsPeriods.map((option) => ({
        value: option,
        text: option,
      }));
    },

    citiesOptions() {
      return this.optionsCities.map((option) => ({
        value: option.name,
        text: option.name,
      }));
    },
  },
  methods: {
    areaOptions() {
      var query = this.fromAddress.district.cityName;

      // console.log(query);
      // console.log(this.districtsCities);
      // console.log('ddddddddddddddddddddddddddd');
      this.filteredItems = this.districtsCities
        .filter((option) => option.cityName === query)
        .map((option) => ({
          value: option.text,
          text: option.text,
        }));
    },

    areaOptionsTo() {
      var query = this.toAddress.district.cityName;

      // console.log(query);
      this.filteredItemsTo = this.districtsCities
        .filter((option) => option.cityName === query)
        .map((option) => ({
          value: option.text,
          text: option.text,
        }));
    },

    getCustomer() {
      this.isLoading = true;

      const query = this.searchQuery;

      if (query.length > 3) {
        DataService.getCustomer(query).then((response) => {
          this.options = response.data.users;
        });
      }
    },
    getService() {
      DataService.getService().then((response) => {
        this.optionsServices = response.data;
      });
    },
    getperiods() {
      DataService.getperiods().then((response) => {
        this.optionsPeriods = response.data;
      });
    },
    getCities() {
      DataService.getCities().then((response) => {
        this.optionsCities = response.data;

        response.data.map((option) => ({
          areasOptions: Object.entries(option.districts).forEach(
            (value, innerKey) => {
              // console.log(`  id:${value[1].id}, name: ${value[1].name}`);
              // console.log(`  id:${option.id}, name: ${option.name}`);
              this.districtsCities.push({
                value: value[1].name,
                text: value[1].name,
                cityName: option.name,
              });
            }
          ),
        }));
      });
    },
    addItem() {
      this.items.push({ name: "", amount: 1 });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    onCityChange(type) {
      if (type === "from") {
        this.fromAddress.district.name = "";
      } else if (type === "to") {
        this.toAddress.district.name = "";
      }
    },
    updateLocation(location) {
      // console.log("we are updating location");
      // console.log(location);
      // console.log("we are updating location");

      // return (location) => {
      if (location && location.lat && location.lng) {
        console.log(location.type);

        if (location.type == "from") {
          this.fromAddress.lat = location.lat;
          this.fromAddress.lng = location.lng;
          // console.log(this.fromAddress)
        } else if (location.type == "to") {
          this.toAddress.lat = location.lat;
          this.toAddress.lng = location.lng;
          // console.log(this.toAddress)
        }
      } else {
        console.error("Invalid location data", location);
      }
      // };
    },
    handleSubmit() {
      // console.log({
      //   ...this.orderData,
      //   fromAddress: this.fromAddress,
      //   toAddress: this.toAddress,
      // });
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          console.log(this.prepareData());
          // alert("Order submitted successfully!");
          this.loading = true;
          this.allerrors = [];

          DataService.create(this.prepareData())
            .then((response) => {
              this.loading = false;
              let responseCode = response.status;
              if (responseCode == 201) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "الطلبات",
                    icon: "EditIcon",
                    variant: "success",
                    text: "تم اﻹضافة بنجاح",
                  },
                });

                this.$router.push("orders");
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;

              if (error.response.data.responseCode == 102) {
                this.allerrors = error.response.data.error;
              }
              let message = Error;
              if (error.response !== undefined) {
                message = error.response.data.message;
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  text: message,
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    prepareData() {
      return {
        customer_id: this.user_id,
        type: 1,
        service: this.service,
        createTime: 0,
        status: 0,
        preferredDate: this.preferredDate,
        preferredTime: this.preferredTime,
        fromAddress: this.fromAddress,
        toAddress: this.toAddress,
        note: this.note,
        items: this.items,
        images: this.uploadedUrls,
      };
    },

    handleFileChange(event) {
      this.files = Array.from(event.target.files); // Store selected files
    },
    async uploadFiles() {
      if (!this.files.length) {
        alert("الرجاء اختيار ملفات ليتم رفعها!.");
        return;
      }

      for (const file of this.files) {
        try {
          // Create a storage reference
          const storageRef = ref(storage, `orders/${file.name}`);
          // Upload the file
          await this.uploadBytes(storageRef, file);
          // Get the file's download URL
          // const downloadURL = await getDownloadURL(storageRef);
          // Add the URL to the list of uploaded URLs
          // this.uploadedUrls.push(downloadURL);
        } catch (error) {
          console.error("Error uploading file:", file.name, error);
        }
      }

      alert("يتم رفع الملفات!");
    },
    async uploadBytes(storageRef, file) {
      // Start the upload
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Monitor upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = progress.toFixed(0); // Update progress
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          this.uploadedUrls.push(url);
        }
      );
    },
    async deleteImage(imge_url, id) {
      if (!imge_url) {
        this.message = "Please enter the image path.";
        return;
      }

      // Create a reference to the file to delete
      // const fileRef = firebaseRef(storage, imge_url);
      const fileRef = ref(storage, imge_url);

      try {
        // Delete the file
        await deleteObject(fileRef);
        this.message = "Image deleted successfully.";
        document.getElementById(`${id}`).remove();
        alert("تم حذف الملف.");
        imge_url = ""; // Reset the input field
      } catch (error) {
        console.error("Error deleting the image:", error);
        this.message = "Failed to delete the image.";
      }
    },
  },
  mounted() {
    this.getService();
    this.getperiods();
    this.getCities();
    this.filteredItems = [];
  },
};
</script>

<style>
.preview-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}
</style>

<style scoped>
.d-flex {
  display: flex;
  align-items: center;
}
.ml-2 {
  margin-left: 8px;
}
.mt-3 {
  margin-top: 16px;
}
.mr-2 {
  margin-left: 8%; /* Adjust spacing between inputs as needed */
}
</style>
