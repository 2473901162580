import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get("/finance/transactions?page=" + page);
  }
  get(id) {
    return axios.get(`/finance/${id}`);
  }
  create(data) {
    return axios.post("/finance", data);
  }
  update(id, data) {
    return axios.put(`/finance`, data);
  }
  getTransactionCount() {
    return axios.get("/finance/transactions?page=1");
  }

  getTransactionTotal() {
    return axios.get("/finance/total");
  }
  delete(id) {
    return axios.delete(`/finance/${id}`);
  }

  filter(page, bankId, typeId) {
    return axios.get(`/finance/transactions/filtered?page=${page}&bankId=${bankId}&typeId=${typeId}`);
  }

  DownloadExcel(limit, bankId, typeId) {
    return axios.get(`/finance/export?limit=${limit}&bankId=${bankId}&typeId=${typeId}`);
  }
  
}
export default new DataService();