<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ headerType }} اللافتات</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="brnadForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >
          <!--  Name -->
          <validation-provider
            #default="validationContext"
            name="اﻹسم"
            rules="required"
          >
            <b-form-group label="اﻹسم" label-for="name">
              <b-form-input
                id="name"
                v-model="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="أكتب اﻹسم"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.name">{{
                allerrors.name[0]
              }}</span>
            </b-form-group>
          </validation-provider>

          <!--  Type -->
          <validation-provider
            #default="validationContext"
            name="اختار الخدمة"
            rules="required"
          >
            <b-form-group
              label="اختار الخدمة"
              label-for="service_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="service_id"
                v-model="service_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="serviceType"
                label="name"
                :reduce="(service_id) => service_id.id"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.service_id">{{
                allerrors.service_id[0]
              }}</span>
            </b-form-group>
          </validation-provider>

          <validation-provider name="الصورة" rules="required">
            <b-row>
              <h2>تحميل الصورة</h2>
            </b-row>
            <b-row class="m-1">
              <input type="file" @change="handleFileChange" />
            </b-row>
            <b-row>
              <p v-if="progress">Progress: {{ progress }}%</p>
            </b-row>

            <b-row class="m-1">
              <img :src="imageUrl" alt="" width="100px" height="150px" />

              <input
                v-model="imageUrl"
                placeholder="Enter image path"
                class="hidden"
              />
            </b-row>

            <b-row>
              <p v-if="message">{{ message }}</p>
            </b-row>

            <b-row>
              <a class="btn btn-danger" v-if="imageUrl" @click="deleteImage"
                >Delete Image</a
              >

              <!-- <p v-if="imageUrl">Uploaded Image URL: <a :href="imageUrl" target="_blank">{{ imageUrl }}</a></p> -->

              <span class="text-danger text-sm" v-if="allerrors.image">{{
                allerrors.image[0]
              }}</span>
            </b-row>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }}
            </b-button>
            <!-- spinner -->
            <b-button v-if="loading" variant="primary" disabled class="mr-1">
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              @click="hide"
            >
              إلغاء
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCardText,
  BMedia,
  BAvatar,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { watch } from "@vue/composition-api";
import DataService from "./data/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  ref as firebaseRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    serviceType: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      headerType: "",
      name: "",
      id: "",
      service_id: "",
      loading: false,
      allerrors: [],

      image: "",
      file: null,
      progress: 0,
      imageUrl: "",
      message: "",
    };
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.dataSlider).length === 0) {
        this.headerType = "إضافة";
        this.initValues();
      } else {
        this.headerType = "تعديل";
        this.id = this.dataSlider.id;
        this.name = this.dataSlider.title;
        this.image = this.dataSlider.image;
        this.imageUrl = this.dataSlider.image;
        this.service_id = this.dataSlider.serviceID;

        // this.initValues()
      }
    },
  },
  methods: {
    initValues() {
      if (this.dataSlider.id) return;
      this.id = null;
      this.name = null;
      this.image = null;
      this.imageUrl = null;
      this.service_id = null;
      this.allerrors = [];
    },

    prepareData() {
      return {
        Image: this.imageUrl,
        Title: this.name,
        ServiceID: this.service_id,
      };
    },

    prepareEditData() {
      return {
        Id: this.id,
        Image: this.imageUrl,
        Title: this.name,
        ServiceID: this.service_id,
      };
    },

    onSubmit() {
      if (!this.id) {
        this.sendData();
      } else {
        this.editData();
      }
    },
    sendData() {
      this.loading = true;
      this.allerrors = [];

      DataService.create(this.prepareData())
        .then((response) => {
          this.loading = false;
          let responseCode = response.status;
          if (responseCode == 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "البنرات اﻹعلانية",
                icon: "EditIcon",
                variant: "success",
                text: "تم اﻹضافة بنجاح",
              },
            });
            this.$emit("update:is-add-new-user-sidebar-active", false);

            DataService.getAll().then((response) => {
              // this.getUserCount()
              this.items = response.data;
              this.$emit("refreshData", this.items);
            });
          } else if (responseCode === 101) {
            console.log("error");
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
    editData() {
      this.allerrors = [];
      this.loading = true;

      DataService.update(this.id, this.prepareEditData())
        .then((response) => {
          this.loading = false;
          // let responseCode = response.data.responseCode
          let responseCode = response.status;
          if (responseCode == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "البنرات اﻹعلانية",
                icon: "EditIcon",
                variant: "success",
                text: "تم التعديل بنجاح",
              },
            });
            this.$emit("update:is-add-new-user-sidebar-active", false);

            DataService.getAll().then((response) => {
              // this.getUserCount()
              this.items = response.data;
              this.$emit("refreshData", this.items);
            });
          } else if (responseCode == 101) {
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },

    handleFileChange(event) {
      this.file = event.target.files[0];
      // },
      // uploadImage() {
      if (!this.file) {
        alert("Please select a file first!");
        return;
      }

      // Create a reference to Firebase Storage
      const storageRef = firebaseRef(
        storage,
        `images/providers/${this.file.name}`
      );

      // Start the upload
      const uploadTask = uploadBytesResumable(storageRef, this.file);

      // Monitor upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = progress.toFixed(0); // Update progress
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          this.imageUrl = url; // Save the image URL
        }
      );
    },
    async deleteImage() {
      if (!this.imageUrl) {
        this.message = "Please enter the image path.";
        return;
      }

      // Create a reference to the file to delete
      const fileRef = firebaseRef(storage, this.imageUrl);

      try {
        // Delete the file
        await deleteObject(fileRef);
        this.message = "Image deleted successfully.";
        this.imageUrl = ""; // Reset the input field
      } catch (error) {
        console.error("Error deleting the image:", error);
        this.message = "Failed to delete the image.";
      }
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
