<template>
  <b-card-code title="الخريطة" id="para">
    <l-map
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="markerLatLng">
        <l-popup>{{ " من " + " : "+fromLocation.district.name+" "+fromLocation.district.cityName }} </l-popup>
      </l-marker>

      <l-marker :lat-lng="markerLatLngTow">
        <l-popup>{{ "إلى  " + " : "+toLocation.district.name+" "+toLocation.district.cityName }} </l-popup>
      </l-marker>

      <!-- <l-marker :lat-lng="markerLatLng" />
      <l-marker :lat-lng="markerLatLngTow" /> -->
      <!-- <l-circle
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="circle.color"
      />
      <l-polygon
        :lat-lngs="polygon.latlngs"
        :color="polygon.color"
      /> -->
    </l-map>

    <!-- <template #code>
      {{ codeMarkerPolygon }}
    </template> -->
  </b-card-code>
</template>

<script>
/* eslint-disable global-require */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  LMap, LTileLayer, LMarker, LCircle, LPolygon,LPopup,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { codeMarkerPolygon } from './code'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    BCardCode,
    LCircle,
    LPolygon,
    LPopup,
  },
  beforeCreate() {
   
    // document.getElementById("para").setAttribute('dir', 'ltr')
  },
  props : {
      fromLocation: {
      type: [Object, Array],
      required: true,
    },
    toLocation : {
      type: [Object, Array],
      required: true,
    },
    waypoints_ob: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      // center: [24.774265, 46.738586],
      // center: [23.8859, 45.0792],
      center: [21.569881, 39.205726],
      markerLatLng: [this.waypoints_ob[0].lat, this.waypoints_ob[0].lng , { draggable: 'true' }],
      markerLatLngTow: [this.waypoints_ob[1].lat, this.waypoints_ob[1].lng, { draggable: 'true' }],
      circle: {
        center: [38.7436056, -2.2304153],
        radius: 4500,
        color: '#EA5455',
      },
      polygon: {
        latlngs: [[47.2263299, -1.6222], [47.21024000000001, -1.6270065], [47.1969447, -1.6136169], [47.18527929999999, -1.6143036], [47.1794457, -1.6098404], [47.1775788, -1.5985107], [47.1676598, -1.5753365], [47.1593731, -1.5521622], [47.1593731, -1.5319061], [47.1722111, -1.5143967], [47.1960115, -1.4841843], [47.2095404, -1.4848709], [47.2291277, -1.4683914], [47.2533687, -1.5116501], [47.2577961, -1.5531921], [47.26828069, -1.5621185], [47.2657179, -1.589241], [47.2589612, -1.6204834], [47.237287, -1.6266632], [47.2263299, -1.6222]],
        color: '#7367F0',
      },
      codeMarkerPolygon,
    }
  },
  mounted() {
    console.log(this.fromLocation)
  },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 500px;
  }
}


</style>

<style>
.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
	right: 0 !important;
  left: unset;
	}
</style>


