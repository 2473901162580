<template>
 <b-card title="">
    <b-table responsive :items="Object.values(orderPayments)"  :fields="tableColumns"> 
        <template #cell(id)="data">
            {{ data.index + 1 }}
        </template>

        <template #cell(time)="data" >
            {{data.value | moment('YY-MM-DD HH:mm:ss')}}
        </template>

        <template #cell(attachment)="data">
            <b-avatar :src="data.value" />
        </template>

        </b-table>
 </b-card>
</template>


<script>
import { BCard ,BAvatar, BTable} from 'bootstrap-vue'
import { number } from 'echarts/lib/export';
export default {
    components: {
        BCard,
        BTable,
        BAvatar
    },
    props : {
        orderPayments: {
            type: [Object, Array],
            required: true,
        }
    },
    data() {
        return {
            tableColumns: [
                { key: 'paidAmount', label:'المبلغ المدفوع', sortable: true },
                { key: 'trxNumber', label: 'الرقم المرجعي للمعاملة', sortable: true },
                { key: 'method', label: 'طريقة الدفع', sortable: true},
                { key: 'description', label: 'الوصف', sortable: true},
                { key: 'attachment', label: 'الملف', sortable: true},
                { key: 'time', label: 'التاريخ', sortable: true},
            ],
        }
    },
    mounted() {
        // console.log("paidAmount");
        // console.log(this.orderPayments.paidAmount);
    },
}
</script>