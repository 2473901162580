<template>
  <b-card-code no-body>
    <!-- <div class="d-flex justify-content-between  flex-wrap"> -->

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"
      :dataSlider="sidebarData"
      :adminType="adminType"
      :currentPage="currentPage"
      @refreshData="refreshData"
    />

    <b-overlay :show="isLoading" rounded="sm">
      <b-button @click="addNewData" variant="primary" class="mb-2">
        إضافة
      </b-button>

      <b-row>
        <b-col cols="6" lg="6">
          <div class="d-flex align-items-center">
            <validation-provider
              #default="validationContext"
              rules="required"
              name="bank"
            >
              <b-form-group label="البنك" label-for="bank">
                <b-form-select
                  id="bank"
                  :state="getValidationState(validationContext)"
                  v-model="bankId"
                  :options="bankOptions"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.bankId">{{
                  allerrors.bankId[0]
                }}</span>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              rules="required"
              name="type"
            >
              <b-form-group label="نوع العمليات" label-for="type">
                <b-form-select
                  id="type"
                  :state="getValidationState(validationContext)"
                  v-model="typeId"
                  :options="typeOptions"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.typeId">{{
                  allerrors.typeId[0]
                }}</span>
              </b-form-group>
            </validation-provider>

            <b-form-group>
              <b-button
                @click="Filter"
                variant="primary"
                class="mb-2"
                style="margin: 45px"
              >
                بحث
              </b-button>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6" lg="6">
          <div class="d-flex align-items-center">
            <!--  limit -->
            <validation-provider
              #default="validationContext"
              name="عدد العناصر"
              rules="required"
            >
              <b-form-group label="عدد العناصر" label-for="amount">
                <b-form-input
                  id="amount"
                  v-model="limitExcel"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="أكتب عدد العناصر"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.amount">{{
                  allerrors.amount[0]
                }}</span>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              rules="required"
              name="bank"
            >
              <b-form-group label="البنك" label-for="bank">
                <b-form-select
                  id="bank"
                  :state="getValidationState(validationContext)"
                  v-model="bankIdExcel"
                  :options="bankOptions"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.bankId">{{
                  allerrors.bankId[0]
                }}</span>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              rules="required"
              name="type"
            >
              <b-form-group label="نوع العمليات" label-for="type">
                <b-form-select
                  id="type"
                  :state="getValidationState(validationContext)"
                  v-model="typeIdExcel"
                  :options="typeOptions"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.typeId">{{
                  allerrors.typeId[0]
                }}</span>
              </b-form-group>
            </validation-provider>

            <b-form-group>
              <b-button
                @click="DownloadExcel"
                variant="primary"
                class="mb-2"
                style="margin: 45px"
              >
                تنزيل الاكسيل
              </b-button>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <table class="table">
        <tr>
          <th colspan="5">اﻹجمالي</th>
          <th>{{ total }}</th>
          <th colspan="2">.</th>
        </tr>
      </table>
      <b-table
        responsive
        :items="items"
        :fields="tableColumns"
        striped
        class="position-relative"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(time)="data">
          {{ data.value | moment("YY-MM-DD HH:mm:ss") }}
        </template>

        <template #cell(attachment)="data">
          <b-avatar :src="data.value" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'walletProvider', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">المحفظة</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="editData(data.item)" >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item> -->

            <!-- <b-dropdown-item @click="deleteData(data.item.id)" > 
                  <feather-icon icon="TrashIcon" />
                  <span  class="align-middle ml-50">حذف</span>
                </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>

      <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          :total-rows="pagination_total"
          :per-page="pagination_per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value) => getData(value)"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-overlay>
    <!-- </div> -->
  </b-card-code>
</template>

<script>
import {
  BTable,
  BCard,
  BCardText,
  BAvatar,
  BOverlay,
  BBadge,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BCardBody,
  BPagination,
  BFormGroup,
  BForm,
  BFormInvalidFeedback,
  BFormInput,
  BRow,
  BCol,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddNew from "./AddNew.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import store from "@/store";
import DataService from "./data/services";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Swal from "sweetalert2";

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BForm,
    BFormInvalidFeedback,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BFormGroup,
    BPagination,
    BFormInput,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      adminType: [],
      pagination_per_page: 20,
      pagination_total: 100,

      currentPage: 1,
      perPage: 1,
      rows: 200,

      allerrors: [],

      total: 0,

      tableColumns: [
        { key: "id", label: "الرقم", sortable: true },
        { key: "description", label: " الوصف", sortable: true },
        { key: "bank", label: "البنك", sortable: true },
        { key: "type", label: "النوع" },
        { key: "amount", label: "المبلغ" },
        { key: "attachment", label: "الملف" },
        { key: "addedByName", label: "تم اﻹضافة بواسطة" },
        { key: "time", label: "التاريخ" },

        // { key: 'actions',label: ' اﻹجراءات' },
      ],

      typeId: "",
      bankId: "",
      limitExcel: "",
      bankIdExcel: "",
      typeIdExcel: "",

      bankOptions: [
        { value: "1", text: "حساب باي موب" },
        { value: "2", text: "البنك اﻷهلي" },
      ],

      typeOptions: [
        { value: "0", text: "أخرى" },
        { value: "1", text: "تسويق" },
        { value: "2", text: "فواتير موردين" },
        { value: "3", text: "طلبيات" },
        { value: "4", text: "سلفيات" },
        { value: "5", text: "مرتبات" },
        { value: "7", text: "فواتير مرتجعات" },
        { value: "9", text: "فواتير سيرفرات" },
        { value: "10", text: "مرتجعات" },
        { value: "11", text: "عمولة البنك" },
        { value: "12", text: "عمولة بوابة الدفع" },
      ],
    };
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      DataService.getAll(page).then((response) => {
        this.getTransactionCount();
        this.isLoading = false;
        // this.items = response.data.data
        this.items = response.data.transactions;

        this.pagination_total = response.data.count;
        this.total = response.data.totalAmount;

        this.currentPage = page;
      });
    },
    getTransactionCount() {
      DataService.getTransactionCount().then((response) => {
        this.pagination_per_page = 20;
        // this.pagination_total = 300;
        // this.pagination_total = response.data.items;
      });
    },
    async deleteData(id) {
      const result = await Swal.fire({
        title: "هل أنت متأكد ؟",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!",
      });

      if (result.isConfirmed) {
        this.isLoading = true;
        DataService.delete(id).then((response) => {
          this.isLoading = false;
          // this.items = response.data

          DataService.getAll(this.currentPage).then((response) => {
            // this.getTransactionCount()
            this.items = response.data.transactions;
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "successfully",
              icon: "BellIcon",
              text: "تم الحذف بنجاح",
              variant: "success",
            },
          });
        });
      }
    },

    getTransactionTotal() {
      DataService.getTransactionTotal().then((response) => {
        this.total = response.data;
        console.log("Transaction");
        console.log(response.data);
      });
    },
    Filter() {
      if (this.bankId != "" || this.typeId != "") {
        this.isLoading = true;
        DataService.filter(this.currentPage, this.bankId, this.typeId).then(
          (response) => {
            this.isLoading = false;
            this.items = response.data.transactions;

            this.pagination_total = response.data.count;
            this.total = response.data.totalAmount;

            // this.currentPage = page;
          }
        );
      }
    },
    DownloadExcel() {
      if (this.limitExcel != "") {
        // this.isLoading = true;
        var downloadExcel = `https://opplusapi.com/api/finance/export?limit=${this.limitExcel}&bankId=${this.bankIdExcel}&typeId=${this.typeIdExcel}`;

        window.open(downloadExcel, "_blank");

        this.isLoading = false;
        // DataService.DownloadExcel(
        //   this.limitExcel,
        //   this.bankIdExcel,
        //   this.typeIdExcel
        // ).then((response) => {
        //   this.isLoading = false;

        //   // this.currentPage = page;
        // });
      }
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editData(data) {
      this.isAddPasswordMode = false;
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.isAddNewSidebarActive = val;
    },
    refreshData(newData) {
      this.items = newData;
    },
  },
  mounted() {
    this.getData(this.currentPage);
    // this.getTransactionTotal();
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
    };
  },
};
</script>
