<template>
  <b-card-code title="المدفوعات">
    
    <!-- select 2 demo -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-orderPayment
        variant="outline-primary"
        class="btn-primary"
        
      >
          إضافة دفعة
      </b-button>


    <!-- select 2 demo -->
    <b-modal 
      @hidden="clearForm"
      hide-footer 
      ref="myModal"
      id="modal-orderPayment"
      title="اضافة دفعة"
      ok-title="submit"
      
      cancel-variant="danger"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="cancelForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >
      

        <validation-provider
            #default="validationContext"
            name="paidAmount"
            rules="required"
          >
          <b-form-group
            label="المبلغ المدفوع"
            label-for="paidAmount"
          >
            <b-form-input
              id="paidAmount"
              v-model="paidAmount"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="أكتب  المبلغ المدفوع"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            <span class="text-danger text-sm" v-if="allerrors.paidAmount">{{ allerrors.paidAmount[0] }}</span>
          </b-form-group>
        </validation-provider>

          <validation-provider
            #default="validationContext"
            name="trxNumber"
            rules="required"
          >
            <b-form-group
              label="رقم العملية"
              label-for="trxNumber"
            >
              <b-form-input
                id="trxNumber"
                v-model="trxNumber"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="أكتب رقم العملية"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.trxNumber">{{ allerrors.trxNumber[0] }}</span>
            </b-form-group>
          </validation-provider>


      
          <b-form-group
            label="اختار طريقة الدفع"
            label-for="vue-select"
          >
            <b-form-select
              id="vue-select"
              v-model="method_way"
              :options="options"
              
              />
          </b-form-group> 

          
           <!--  description -->
           <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              label="الوصف"
              label-for="name"
            >
              <b-form-input
                id="description"
                v-model="description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="أكتب الوصف"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.description">{{ allerrors.description[0] }}</span>
            </b-form-group>
          </validation-provider>

         

          <validation-provider
            name="image"
            rules="required"
          >
            <b-row>
              <h2>تحميل الصورة</h2>
            </b-row>
            <b-row class="m-1">
              <input type="file" @change="handleFileChange" />
            </b-row>
            <b-row>
              <p v-if="progress">Progress: {{ progress }}%</p>
            </b-row>

            <b-row class="m-1">
              <img :src="imageUrl" alt="" width="100px" height="150px">

              <input v-model="imageUrl" placeholder="Enter image path" class="hidden" />
            </b-row>

            <b-row>
              <p v-if="message">{{ message }}</p>
            </b-row>

            <b-row>
              <a class="btn btn-danger"  v-if="imageUrl" @click="deleteImage">Delete Image</a>

              <!-- <p v-if="imageUrl">Uploaded Image URL: <a :href="imageUrl" target="_blank">{{ imageUrl }}</a></p> -->

              <span class="text-danger text-sm" v-if="allerrors.image">{{ allerrors.image[0] }}</span>
            </b-row>

          </validation-provider>              

  

        
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              إرسال
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            
          </div>

        
      </b-form>
            
    </validation-observer>


    
          
    </b-modal>


   
   
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>

import {
  BLink,BCard,  BAvatar, BBadge, BOverlay,
  BRow, BCol,BSidebar, BForm, BFormTextarea,BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormFile, BCardText, BMedia, BSpinner,BFormSelect, BModal, VBModal, BAlert
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { watch } from '@vue/composition-api'
import DataService from '../data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref as firebaseRef, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";

import moment from "moment";


import BCardCode from '@core/components/b-card-code'

import { codeBasic } from './code'

export default {
  components: {
    BModal,
    BAlert,
    BCardCode,
    BLink,
    BCard, 
    BBadge, 
    BOverlay,
    BRow, 
    BCol,
    BSidebar,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    BFormSelect,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,

    moment,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      codeBasic,

      loading:false,
      isLoading: false,
      allerrors: [],

      method_way: null,
      trxNumber: null,
      attachment:null,
      time:null,

      paidAmount:'',
      description:'',

      image:'',
      file: null,
      progress: 0,
      imageUrl: "",
      message: "",
      
      
      orderId:this.$route.params.id,
      options: [
        { value: 'حوالة بنكية', text: 'حوالة بنكية' },
        { value: 'دفع الكتروني', text: 'دفع الكتروني' },
        { value: 'كاش', text: 'كاش' },
      ],

    }
  },
  
  mounted() {
    
  },
  methods: {
    prepareData(){
      return {
        orderId: this.orderId, 
        paidAmount: this.paidAmount,
        trxNumber: this.trxNumber,
        method: this.method_way,
        description: this.description,
        attachment: this.imageUrl,
        time: moment().unix(),
      }
    },
    onSubmit(){
        this.sendData()
    },
    sendData(){
      this.loading = true;
      this.allerrors = []

        DataService.addPayment(this.prepareData())
        .then(response => {
          this.loading = false
          
          let responseCode = response.status
          if(responseCode == 204){
             

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: 'EditIcon',
                variant: 'success',
                text: "تم اﻹرسال بنجاح"
              },
            })

            this.$emit('refreshData',this.orderId)

            this.$refs.myModal.hide();
      
          }else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    clearForm() {
      // Reset form data when the modal is closed
      this.formData = {
        orderId: "",
        method_way: "",
      };
      console.log("Form cleared");
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.image = file
      this.imageUrl = URL.createObjectURL(file);
    },

    handleFileChange(event) {
      this.file = event.target.files[0];
    // },
    // uploadImage() {
      if (!this.file) {
        alert("Please select a file first!");
        return;
      }

      // Create a reference to Firebase Storage
      const storageRef = firebaseRef(storage, `images/payments/${this.file.name}`);

      // Start the upload
      const uploadTask = uploadBytesResumable(storageRef, this.file);

      // Monitor upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = progress.toFixed(0); // Update progress
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          this.imageUrl = url; // Save the image URL
        }
      );
    },
    async deleteImage() {
      if (!this.imageUrl) {
        this.message = "Please enter the image path.";
        return;
      }

      // Create a reference to the file to delete
      const fileRef = firebaseRef(storage, this.imageUrl);

      try {
        // Delete the file
        await deleteObject(fileRef);
        this.message = "Image deleted successfully.";
        this.imageUrl = ""; // Reset the input field
      } catch (error) {
        console.error("Error deleting the image:", error);
        this.message = "Failed to delete the image.";
      }
    },
  },
  setup() {
   
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button

    }
  },
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


