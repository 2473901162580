<template>

<b-overlay
    :show="isLoading"
    rounded="sm"
>

<!-- <section id="dashboard-ecommerce" v-if="statisticsData"> -->
<section id="dashboard-ecommerce" >
     <b-row class="match-height">

     <!-- statistics card -->
      <b-col xl="6"  md="12"   >
        <!-- <div v-if="!isDataLoaded">Loading...</div> -->
        <!-- v-else   -->
        <statistics   :statisticsData="statisticsData" />
      </b-col>
     <!-- End statistics card -->

    <!-- order chart card -->
      <b-col xl="6"  md="12" >
        <order-statistic :data="orderStatistic" />
      </b-col>
    <!-- End order chart card -->
    </b-row>


    <b-row>
        <!-- Transaction Card -->
      <!-- <b-col  lg="4" md="6"  >
        <transaction-order :data="transactionData" />
      </b-col> -->
      <!--/ End Transaction Card -->


      <!-- custoemr chart card -->
        <!-- <b-col lg="8" md="12">
            <customer-chart />
        </b-col> -->
      <!-- End custoemr chart card -->

    </b-row>

    <b-row>
        <!-- order chart card -->
      <b-col
        lg="12"
        md="12"
      >
        <p>الطلبات</p>
        <!-- <order-chart :data="data.transactionData" /> -->
        <order-index/>
      </b-col>
      <!--/ End Order chart-->

    </b-row>

</section>

</b-overlay>
</template>

<script>
import statistics from './components/statistics.vue'
import orderStatistic from './components/order.vue'
import transactionOrder from './components/transaction.vue'
import orderChart from './components/orderChart.vue'
import customerChart from './components/customerChart.vue'
import { BRow, BCol } from 'bootstrap-vue'
import Customer from '../customer/index.vue'
import OrderIndex from '../orders/index.vue'

import {BOverlay} from 'bootstrap-vue'
import DataService  from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
    BRow,
    BCol,
    statistics,
    orderChart,
    orderStatistic,
    transactionOrder,
    customerChart,
    Customer,
    OrderIndex,

    ToastificationContent,
    BOverlay
},
    data() {
        return {
            isLoading: '',
            isDataLoaded: false,
            statisticsData: {},
            orderStatistic: {
                 title: 'إحصائيات الطلبات',
                lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
                totalTicket: 0,
                newTicket: 0,
                openTicket: 0,
                responseTime: 0,
                supportTrackerRadialBar: {
                    series: [],
                },
            },
            transactionData: [
                    {
                    mode: 'All Order',
                    types: 'Starbucks',
                    avatar: 'PocketIcon',
                    avatarVariant: 'primary',
                    payment: '',
                    deduction: false,
                    },
                    {
                    mode: 'Pendding',
                    types: 'Add Money',
                    avatar: 'ClockIcon',
                    avatarVariant: 'warning',
                    payment: '',
                    deduction: false,
                    },
                    {
                    mode: 'Complate',
                    types: 'Add Money',
                    avatar: 'CheckIcon',
                    avatarVariant: 'info',
                    payment: '',
                    deduction: false,
                    },
                    {
                    mode: 'cancel',
                    types: 'Ordered Food',
                    avatar: 'XCircleIcon',
                    avatarVariant: 'danger',
                    payment: '',
                    deduction: true,
                    },
                    
                ],
            data: {
                statistics: [],

                transactionData: [
                    {
                    mode: 'All Order',
                    types: 'Starbucks',
                    avatar: 'PocketIcon',
                    avatarVariant: 'primary',
                    payment: '-$74',
                    deduction: true,
                    },
                    {
                    mode: 'Pendding',
                    types: 'Add Money',
                    avatar: 'ClockIcon',
                    avatarVariant: 'warning',
                    payment: '+$480',
                    deduction: false,
                    },
                    {
                    mode: 'Complate',
                    types: 'Add Money',
                    avatar: 'CheckIcon',
                    avatarVariant: 'info',
                    payment: '+$480',
                    deduction: false,
                    },
                    {
                    mode: 'cancel',
                    types: 'Ordered Food',
                    avatar: 'XCircleIcon',
                    avatarVariant: 'danger',
                    payment: '-$23',
                    deduction: true,
                    },
                    {
                    mode: 'Confirm',
                    types: 'Refund',
                    avatar: 'TrendingUpIcon',
                    avatarVariant: 'success',
                    payment: '+$98',
                    deduction: false,
                    },
                ],
            }
        }
    },

    methods: {
        prepareData(){
            return {
                fromDate: "2019-05-01",
	            toDate: "2022-08-01"
            }
        },
        getData(){
            this.isLoading = true;
            DataService.getDashboardCount()
            .then(response => {
                this.isLoading = false
                this.statisticsData= response.data

                this.orderStatistic.totalTicket = response.data[0].countOrders
                this.orderStatistic.newTicket = response.data[0].countOrdersNew
                this.orderStatistic.openTicket = response.data[0].countOrdersCompleted
                this.orderStatistic.responseTime = response.data[0].countOrdersCanceled
                this.orderStatistic.supportTrackerRadialBar.series = this.calclatePercentage(response.data[0].countOrders, response.data[0].countOrdersCompleted)
                this.isDataLoaded = true  


            //     this.orderStatistic.totalTicket = response.data.data.orderStatistic.allOrder
            //     this.orderStatistic.newTicket = response.data.data.orderStatistic.pendingOrder
            //     this.orderStatistic.openTicket = response.data.data.orderStatistic.completOrder
            //     this.orderStatistic.responseTime = response.data.data.orderStatistic.canceleOrder
                
            })

        },


        calclatePercentage(all, compalet){
            return [Math.round((compalet / all) * 100)]
        }
    },
    mounted() {
        this.getData()
    },
}
</script>