export default [
  {
    title: 'لوحة التحكم',
    route: '/',
    icon: 'HomeIcon',
  },
  {
    title: 'الطلبات',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'الطلبات غير النشطة',
    route: 'ordersInActive',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'المدراء',
    route: 'admin',
    icon: 'UserCheckIcon',
  },
  {
    title: 'العملاء',
    route: 'customer',
    icon: 'UserIcon',
  },
  // {
  //   title: 'مزودي الخدمات',
  //   route: 'provider',
  //   icon: 'UserPlusIcon',
  // },
  {
    title: 'ملف مزودي الخدمات',
    route: 'providerProfile',
    icon: 'FolderPlusIcon',
  },
  {
    title: 'المعاملات',
    route: 'transactions',
    icon: 'DollarSignIcon',
  },
  {
    title: 'الخدمات',
    route: 'service',
    icon: 'ActivityIcon',
  },
  {
    title: 'اﻷسعار',
    route: 'price',
    icon: 'DollarSignIcon',
  },
  {
    title: 'الفترات الزمنية',
    route: 'timePeriod',
    icon: 'CalendarIcon',
  },
  {
    title: 'السياسات',
    route: 'policy',
    icon: 'TypeIcon',
  },
  {
    title: 'اﻹشعارات',
    route: 'firbase',
    icon: 'SendIcon',
  },
  
  {
    title: 'البنرات اﻹعلانية',
    route: 'banner',
    icon: 'BookOpenIcon',
  },
 
]
