import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/orders/moving/active?page=" + page);
    }

    getAllInActive(page) {
        return axios.get("/orders/moving/inactive?page=" + page);
    }

    get(id) {
        return axios.get(`/orders/${id}`);
    }
    getOrderLogs(id) {
        return axios.get(`/orders/moving/${id}/logs`);
    }
    getOrderCount() {
        return axios.get(`/orders/moving/active/count`);
    }
    getOrderInActiveCount() {
        return axios.get(`/orders/moving/inactive/count`);
    }

    getCustomer(keyword) {
        return axios.get(`/users?accountType=0&query=${keyword}`);
        // return axios.get(`/users/search?keyword=${keyword}&page=1`);
    }

    create(data) {
        return axios.post(`/orders/moving/admin?customerId=${data.customer_id}`, data);
    }
    getService() {
        return axios.get(`/services`);
    }

    getperiods() {
        return axios.get(`/orders/periods`);
    }

    getCities() {
        return axios.get(`/addresses/cities`);
    }
    

    updateProvider(data) {
        return axios.put(`/orders/updateProvider?orderId=${data.orderId}&providerId=${data.providerId}`);
    }

    updateStatus(data) {
        return axios.put(`/orders/moving?orderId=${data.orderId}&status=${data.status_id}`)
    }

    cancelOrder(data) {
        return axios.put(`/orders/moving/cancel?orderId=${data.orderId}&cancelReason=${data.cancelReason}`)
    }

    addPayment(data) {
        return axios.post("/payments/extra", data);
    }
}
export default new DataService();