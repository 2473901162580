<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-row>
      <b-col cols="12">
        <modal-update-status @refreshData="refreshData" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="12">
        <order-data
          :orderData="orderData"
          :orderDataItemsNumber="orderDataItemsNumber"
          :orderStatus="orderStatus"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <customer-info :userData="userData" />
      </b-col>

      <b-col cols="12" lg="6">
        <!-- <b-row>
          <b-col cols="12" lg="6"> -->
        <modal-update-provider @refreshData="refreshData" />
        <!-- </b-col>
        </b-row> -->

        <provider v-if="provider" :provider="provider" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" lg="6">
        <order-from-address :fromLocation="fromLocation" />
      </b-col>

      <b-col cols="6" lg="6">
        <order-to-address :toLocation="toLocation" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <order-status :orderDataStatus="orderDataStatus" />
      </b-col>

      <b-col cols="12" lg="6">
        <order-products :orderProducts="orderProducts" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="12">
        <modal-add-payment @refreshData="refreshData" />
        <order-payments :orderPayments="orderPayments" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" lg="6">
        <div v-if="!isDataLoaded">Loading...</div>
        <swiper-multiple-slides v-else :orderImages="orderImages" />
      </b-col>

      <b-col cols="6" lg="6">
        <div v-if="!isDataLoaded">Loading...</div>
        <order-maps-marker
          v-else
          :fromLocation="fromLocation"
          :toLocation="toLocation"
          :waypoints_ob="waypoints_ob"
        />
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col cols="6" lg="6">
        <div v-if="!isDataLoaded">Loading...</div>
        <order-maps-marker v-else  :fromLocation="fromLocation" :toLocation="toLocation" :waypoints_ob="waypoints_ob"/>
        
      </b-col>
    </b-row> -->

    <!-- <b-card>
    <tab  :machineData="machineData" :orderData="orderData"/>
  </b-card> -->
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DataService from "./data/services";
import Tab from "./profile/tab";

import customerInfo from "./components/customerInfo.vue";
import provider from "./components/provider.vue";
import orderStatus from "./components/orderStatus.vue";
import orderProducts from "./components/orderProducts.vue";
import orderPayments from "./components/orderPayments.vue";
import orderData from "./components/orderData.vue";
import orderMaps from "./components/orderMaps.vue";
import orderMapsMarker from "./components/orderMapsMarker.vue";
import orderFromAddress from "./components/orderFromAddress.vue";
import orderToAddress from "./components/orderToAddress.vue";
import SwiperNavigation from "./components/SwiperNavigation.vue";
import SwiperMultipleSlides from "./components/SwiperMultipleSlides.vue";
import ModalUpdateProvider from "./components/ModalUpdateProvider.vue";
import ModalUpdateStatus from "./components/ModalUpdateStatus.vue";
import ModalAddPayment from "./components/ModalAddPayment.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BOverlay,
    ToastificationContent,
    Tab,
    customerInfo,
    provider,
    orderStatus,
    orderProducts,
    orderPayments,
    orderData,
    orderMaps,
    orderMapsMarker,
    SwiperNavigation,
    SwiperMultipleSlides,
    ModalUpdateProvider,
    ModalUpdateStatus,
    ModalAddPayment,
    orderFromAddress,
    orderToAddress,
  },

  data() {
    return {
      items: [],
      userData: {},
      machineData: [],
      provider: {},
      orderData: [],
      orderPayments: [],
      orderDataItemsNumber: 0,
      orderDataStatus: [],
      orderImages: [],
      isLoading: true,
      orderProducts: {},
      fromLocation: {},
      toLocation: {},
      waypoints_ob: [],
      isDataLoaded: false,
      // status: [
      //   {
      //     0: 'في انتظار عروض الاسعار', 1: 'مؤكدة', 2: 'تم الوصول لموقع الطلب', 3: 'مكتملة', 4: 'ملغية', 5: 'تم الوصول لموقع الطلب', 6: 'في الطريق لموقع التسليم',
      //   },
      //   {
      //     0: 'primary', 1: 'info', 2: 'danger', 3: 'success', 4: 'danger', 5: 'info', 6: 'info',
      //   }
      // ],
      orderStatus: 0,
    };
  },
  methods: {
    getData() {
      this.isLoading = true;
      var arr = [];
      DataService.get(this.$route.params.id)
        .then((response) => {
          // console.log("images");
          // console.log(response.data.images);

          this.isDataLoaded = true; // Set flag to true once data is loaded

          this.isLoading = false;
          this.items = response.data;
          this.userData = this.items.customer;
          this.provider = this.items.provider;
          this.orderData = this.items;
          this.orderDataItemsNumber = this.items.items.length;
          this.orderStatus = this.items.status;
          this.orderProducts = this.items.items;
          this.orderPayments = this.items.paymentList;
          // console.log('status ='+ this.orderStatus)
          this.fromLocation = this.items.fromAddress;
          this.toLocation = this.items.toAddress;
          this.waypoints_ob = [
            {
              lat: this.items.fromAddress.lat,
              lng: this.items.fromAddress.lng,
            },
            { lat: this.items.toAddress.lat, lng: this.items.toAddress.lng },
          ];

          // console.log("paidAmount");
          // console.log(this.orderPayments.paidAmount);

          // for (let index = 0; index < this.items.images.length; index++) {
          for (const [key, value] of Object.entries(this.items.images)) {
            var obj = {};
            // obj['key'] = `${key}`;
            obj["img"] = `${value}`;
            arr.push(obj);
          }

          // console.log(arr)
          // this.orderImages = this.items.images;
          this.orderImages = arr;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.isDataLoaded = false; // Handle error
        });
    },
    getOrderLogs() {
      this.isLoading = true;
      DataService.getOrderLogs(this.$route.params.id).then((response) => {
        this.orderDataStatus = response.data;
      });
    },
    changeStatus(id) {
      this.isLoading = true;
      DataService.changeStatus(id).then((response) => {
        this.isLoading = false;
        if (this.userData.status == 1) {
          this.userData.status = 0;
        } else {
          this.userData.status = 1;
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "successfully",
            icon: "BellIcon",
            text: response.data.responseDescription,
            variant: "success",
          },
        });
      });
    },
    refreshData(newData) {
      // this.items = newData

      this.getData();
      console.log("Loading");
      // console.log(newData);
      // this.provider = newData.provider
    },
  },
  mounted() {
    this.getData();
    this.getOrderLogs();
  },
};
</script>

<style scoped>
/* Force LTR direction for this container */
.ltr-container {
  direction: ltr;
  text-align: left;
}
</style>
