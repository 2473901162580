<template>
  <b-sidebar
    id="add-new-debit"
    :visible="isAddNewDebitSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-debit-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ headerType }} سحب</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="brnadForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >
          <!--  Name -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group label="الوصف" label-for="description">
              <b-form-input
                id="description"
                v-model="description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="أكتب الوصف"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.description">{{
                allerrors.description[0]
              }}</span>
            </b-form-group>
          </validation-provider>

          <!-- amount -->
          <validation-provider
            #default="validationContext"
            name="amount"
            rules="required|integer"
          >
            <b-form-group label="المبلغ" label-for="amount">
              <b-form-input
                id="amount"
                v-model="amount"
                :state="getValidationState(validationContext)"
                trim
                placeholder="أدخل المبلغ "
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

              <span class="text-danger text-sm" v-if="allerrors.amount">{{
                allerrors.amount[0]
              }}</span>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }}
            </b-button>
            <!-- spinner -->
            <b-button v-if="loading" variant="primary" disabled class="mr-1">
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              @click="hide"
            >
              إلغاء
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCardText,
  BMedia,
  BAvatar,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { watch } from "@vue/composition-api";
import DataService from "./data/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import md5 from "js-md5";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BFormSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewDebitSidebarActive",
    event: "update:is-add-new-debit-active",
  },
  props: {
    isAddNewDebitSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    // isAddPasswordMode: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      required,
      alphaNum,
      headerType: "",
      isAddPasswordMode: false,
      description: "",
      amount: "",
      userId: "",
      id: "",
      loading: false,
      allerrors: [],
      items: [],
    };
  },
  watch: {
    isAddNewDebitSidebarActive(val) {
      // console.log("newVal:", val, "Type:", typeof val);
      if (typeof val !== "boolean") {
        throw new Error("input is invalid type");
      }

      if (!val) return;
      if (Object.entries(this.dataSlider).length === 0) {
        this.headerType = "إضافة";
        this.isAddPasswordMode = true;
        this.initValues();
      } else {
        this.headerType = "تعديل";
        this.isAddPasswordMode = false;
        this.id = this.dataSlider.id;
        this.description = this.dataSlider.description;
        this.amount = this.dataSlider.amount;
        this.userId = this.$route.params.id;

        // this.initValues()
      }
    },
  },
  methods: {
    initValues() {
      if (this.dataSlider.id) return;
      this.id = null;
      this.description = null;
      this.amount = null;
      this.userId = this.$route.params.id;
      this.allerrors = [];
    },
    prepareData() {
      return {
        id: this.id,
        description: this.description,
        amount: this.amount * -1,
        userId: this.$route.params.id,
      };
    },
    onSubmit() {
      if (!this.id) {
        this.sendData();
      } else {
        this.editData();
      }
    },
    sendData() {
      this.loading = true;
      this.allerrors = [];

      DataService.create(this.prepareData())
        .then((response) => {
          this.loading = false;
          let responseCode = response.status;
          if (responseCode == 204) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "سحب",
                icon: "EditIcon",
                variant: "success",
                text: "تم اﻹضافة بنجاح",
              },
            });
            this.$emit("update:is-add-new-debit-active", false);

            DataService.getAll(this.currentPage, this.$route.params.id).then(
              (response) => {
                // this.getUserCount()
                this.items = response.data.transactions;
                this.$emit("refreshData", this.items);
              }
            );

            // this.$emit('refreshData',response.data)
          } else if (responseCode === 101) {
            console.log("error");
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
    editData() {
      this.allerrors = [];
      this.loading = true;

      DataService.update(this.id, this.prepareData())
        .then((response) => {
          this.loading = false;
          // let responseCode = response.data.responseCode
          let responseCode = response.status;
          if (responseCode == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "السحب",
                icon: "EditIcon",
                variant: "success",
                text: "تم التعديل بنجاح",
              },
            });
            this.$emit("update:is-add-new-debit-active", false);

            DataService.getAll(this.currentPage).then((response) => {
              // this.getUserCount()
              this.items = response.data;
              this.$emit("refreshData", this.items);
            });

            // this.$emit('refreshData',response.data)
          } else if (responseCode == 101) {
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-debit {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
