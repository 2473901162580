import axios from '@/libs/axios'

class DataService {
  getAll(page,query) {
    if (query !== undefined) {
      return axios.get(`/users?accountType=2&query=${query}`);
    }
    else{
      return axios.get("/users?accountType=2&page=" + page);
    }
    
  }
  get(id) {
    return axios.get(`/users/${id}`);
  }
  create(data) {
    return axios.post("/auth/admin/signup", data);
  }
  update(id, data) {
    // return axios.put(`/users/${id}`, data);
    return axios.put(`/users`, data);
  }
  delete(id) {
    return axios.delete(`/users/${id}`);
  }
  changePassword(id) {
    return axios.get(`/changePassword/${id}`);
  }
  getUserCount() {
    // return axios.get(`/users/count?accountType=2`);
    return axios.get("/users/count?accountType=2");

  }
}
export default new DataService();