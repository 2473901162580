<template>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >


    <b-row>
      <b-col
          cols="12"
          lg="12"
        >
          <!-- <provider-info :providerData="providerData" /> -->

        <b-card title="ملف مزود الخدمة">

          
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> الصورة</span>
            </th>
            <td>
              <span class="b-avatar-img">
                <a v-if="imageUrl" :href="imageUrl" target="_blank">
                  <img :src="imageUrl" class="avatar"  alt="" width="30px" height="60px">
                </a>
              </span>
            </td>
            <th>
              <feather-icon
                icon="StatusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">حالة المستخدم</span>
            </th>
            <td colspan="3">
            
              <b-badge @click="changeStatus(providerData.user.id,status[0][providerData.user.status])" :variant="status[1][providerData.user.status]" style="cursor: pointer;">
                {{ status[0][providerData.user.status] }}
              </b-badge>
            </td>
          </tr>

          <tr  >
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اسم  المستخدم</span>
            </th>
            <td class="pb-50">
              {{ providerData.user.name }}
            </td>

            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">رقم الهاتف</span>
            </th>
            <td>
              {{ providerData.user.phone }}
            </td>

            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الايميل </span>
            </th>
            <td>
              {{ providerData.user.email }}
            </td>
          </tr>

          <tr  >
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اسم مزود الخدمة</span>
            </th>
            <td class="pb-50">
              {{ providerData.name }}
            </td>

            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">رقم السجل</span>
            </th>
            <td>
              {{ providerData.registrationNumber }}
            </td>

            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">رقم اﻷيبان</span>
            </th>
            <td>
              {{ providerData.ibanNumber }}
            </td>
          </tr>


         

          
          
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">التقييم</span>
            </th>
            <td class="pb-50">
              {{ providerData.rating }}
            </td>
          
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">عدد التقييمات</span>
            </th>
            <td class="pb-50">
              {{ providerData.reviewsCount }}
            </td>
         
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المشاريع المكتملة</span>
            </th>
            <td class="pb-50">
              {{ providerData.completedProjects }}
            </td>
          </tr>
 
         

          <tr>

            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المدينة </span>
            </th>
            <td>
              {{ providerData.cityName }}
            </td>

            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الوصف</span>
            </th>
            <td colspan="3">
              {{ providerData.description }}
            </td>
         
            
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الخدمات</span>
            </th>
            <td>
              <div v-for="(item, index) in providerData.services" :key="index" class="m-2">
                
                <!-- <span class="b-avatar-img">
                  <b-avatar :src="item.image" />
                </span> -->

                {{ index+1 }} / {{ item.name }}

              </div>
              
            </td>

            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">صورة اﻷيبان</span>
            </th>
            <td class="pb-50">
              <span class="b-avatar-img">
                <a v-if="providerData.ibanAttachment" :href="providerData.ibanAttachment" target="_blank"> <img :src="providerData.ibanAttachment" class="avatar"  alt="" width="40px" height="70px"> </a>
              </span>
            </td>
          
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">صورة السجل</span>
            </th>
            <td class="pb-50">
              <span class="b-avatar-img">
                <a v-if="providerData.registrationAttachment" :href="providerData.registrationAttachment" target="_blank">  <img :src="providerData.registrationAttachment"  class="avatar"  alt="" width="40px" height="70px"> </a>
              </span>
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المراجعات</span>
            </th>
            <td>
              
              <b-link :to="{ name: 'review' }">
                <feather-icon icon="FolderIcon" />
                <span class="align-middle ml-50">المراجعات</span>
              </b-link>

            </td>
          </tr> 

          
        </table>
    </b-card>
        </b-col>

      
    </b-row>



</b-overlay>

</template>

<script>
import {
  BLink,BCard, BButton, BAvatar, BRow, BCol, BBadge, BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataService  from './data/services'

import providerInfo from './components/providerInfo.vue'
export default {
  components: {
    BLink,BCard, BButton, BRow, BCol, BAvatar, BBadge, BOverlay,
    ToastificationContent,
    providerInfo,
  },

  data() {
    return {
      providerData: {},
      isLoading: true,
      isDataLoaded: false,
      imageUrl:"",
      status: [
        {
          1: 'Active', 0: 'Inactive',
        },
        {
          1: 'success', 0: 'danger', 
        }
      ],
      
    }
  },
  methods: {
      getData(){
            this.isLoading = true;
            var arr = [];

            DataService.get(this.$route.params.id)
            .then(response => {

                  this.isDataLoaded = true; // Set flag to true once data is loaded

                  this.isLoading = false
                  this.providerData    = response.data
                  this.imageUrl  = response.data.image,
                  
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                              title: 'successfully',
                              icon: 'BellIcon',
                              text: response.data.responseDescription,
                              variant: 'success',
                        },
                  })
            }).catch((error) => {
              console.error("Error fetching data:", error);
              this.isDataLoaded = false; // Handle error
            });
      },

      async changeStatus(id,status){
      this.isLoading = true;

      
      if(status === 'Inactive')
      {
        status = 1;
      }else if(status === 'Active')
      {
        status = 0;
      }
      

      axios.put('/users/UpdateUserStatus', {
        id: id,
        status: status,
      })
      .then(response => {
        this.isLoading = false

        this.getData();

        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: "تم تغيير حالة المستخدم",
              variant: 'success',
            },
          })
      })
      .catch(error => {
        console.log(error);
      });

      

    },
     
      
  },
  mounted() {
    this.getData()
  },
  
}
</script>

<style scoped>
/* Force LTR direction for this container */
.ltr-container {
  direction: ltr;
  text-align: left;
}

table tr {
  height: 35px;
}

table th {
  white-space: nowrap;
}

</style>
