<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-max"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ headerType }} ملف مزود خدمة</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="brnadForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >
          <b-row>
            <b-progress
              :value="progress"
              max="100"
              class="mt-3"
              v-if="progress"
            ></b-progress>

            <b-col cols="6" lg="6">
              <!--  Name -->
              <validation-provider
                #default="validationContext"
                name="اﻹسم"
                rules="required"
              >
                <b-form-group label="اﻹسم" label-for="userName">
                  <b-form-input
                    id="userName"
                    v-model="userName"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب اﻹسم"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!-- Email -->
              <validation-provider
                #default="validationContext"
                name="اﻹيميل"
                rules="required|email"
              >
                <b-form-group label="اﻹيميل" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Enter Email Address"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!--  Name -->
              <validation-provider
                #default="validationContext"
                name="اسم مزود الخدمة"
                rules="required"
              >
                <b-form-group label="اسم مزود الخدمة" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب اسم مزود الخدمة"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!--  toCity -->
              <validation-provider
                #default="validationContext"
                rules="required"
                name="المدينة"
              >
                <b-form-group label="المدينة" label-for="cityId">
                  <b-form-select
                    id="cityId"
                    v-model="cityId"
                    :options="optionsCities"
                  ></b-form-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!-- description -->
              <validation-provider
                #default="validationContext"
                name="الوصف"
                rules="required"
              >
                <b-form-group label="الوصف" label-for="description">
                  <!-- <b-form-input
                id="description"
                v-model="description"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter description Address"
              /> -->

                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Enter description Address"
                    rows="5"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span
                    class="text-danger text-sm"
                    v-if="allerrors.description"
                    >{{ allerrors.description[0] }}</span
                  >
                </b-form-group>
              </validation-provider>

              <validation-provider name="imageUrl">
                <b-form-group label="صورة مزود الخدمة" label-for="imageUrl">
                  <b-form-file
                    id="file"
                    @change="handleFileChange($event, 'providers')"
                    placeholder="Choose a file..."
                  ></b-form-file>
                </b-form-group>

                <span class="text-danger text-sm" v-if="allerrors.name">{{
                  allerrors.name[0]
                }}</span>

                <b-row v-if="imageUrl">
                  <a :href="imageUrl" target="_blank">
                    <img
                      :src="imageUrl"
                      alt=""
                      width="60px"
                      height="90px"
                      class="avatar m-1"
                    />
                  </a>

                  <div>
                    <p v-if="message" class="m-1">{{ message }}</p>

                    <a
                      class="btn btn-sm btn-danger m-1"
                      @click="deleteImage(imageUrl, 'providers')"
                      >حذف</a
                    >
                  </div>
                </b-row>
              </validation-provider>

              <validation-provider name="registrationAttachment">
                <b-form-group
                  label="صورة  شهادة التسجيل"
                  label-for="registrationAttachment"
                >
                  <b-form-file
                    id="registrationAttachment"
                    @change="handleFileChange($event, 'registrationAttachment')"
                    placeholder="Choose a file..."
                  ></b-form-file>
                </b-form-group>

                <span class="text-danger text-sm" v-if="allerrors.name">{{
                  allerrors.name[0]
                }}</span>

                <b-row v-if="registrationAttachment">
                  <a :href="registrationAttachment" target="_blank">
                    <img
                      :src="registrationAttachment"
                      alt=""
                      width="60px"
                      height="90px"
                      class="avatar m-1"
                    />
                  </a>
                  <div>
                    <p v-if="registrationAttachmentMessage" class="m-1">
                      {{ registrationAttachmentMessage }}
                    </p>

                    <a
                      class="btn btn-sm btn-danger m-1"
                      @click="
                        deleteImage(
                          registrationAttachment,
                          'registrationAttachment'
                        )
                      "
                      >حذف</a
                    >
                  </div>
                </b-row>
              </validation-provider>
            </b-col>
            <b-col cols="6" lg="6">
              <!-- Phone -->
              <validation-provider
                #default="validationContext"
                name="الهاتف"
                rules="required|digits:10|integer"
              >
                <b-form-group label="الهاتف" label-for="phone">
                  <b-form-input
                    id="phone"
                    v-model="userPhone"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Enter Phone"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>

                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!-- Password -->
              <validation-provider
                #default="validationContext"
                name="كلمة السر"
                rules="required|password"
                v-if="isAddPasswordMode"
              >
                <b-form-group label="كلمة السر" label-for="password">
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب كلمة السر"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!--  registrationNumber -->
              <validation-provider
                #default="validationContext"
                name="رقم السجل"
                rules="required"
              >
                <b-form-group label="رقم السجل" label-for="name">
                  <b-form-input
                    id="registrationNumber"
                    v-model="registrationNumber"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب رقم السجل"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!--  ibanNumber -->
              <validation-provider
                #default="validationContext"
                name="رقم اﻷيبان"
                rules="required|ibanRule"
              >
                <b-form-group label="رقم اﻷيبان" label-for="name">
                  <b-form-input
                    id="ibanNumber"
                    v-model="ibanNumber"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب رقم اﻷيبان"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                rules="required"
                name="اسم العميل في البنك"
              >
                <b-form-group label="اسم العميل في البنك" label-for="bank">
                  <b-form-input
                    id="registrationNumber"
                    v-model="bankAccountName"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب  اسم العميل في البنك"
                  />

                  <!-- <b-form-select
                id="bank"
                :state="getValidationState(validationContext)"
                v-model="bankAccountName"
                :options="bankOptions"
                
              ></b-form-select> -->
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!--  servicesIds -->
              <validation-provider
                #default="validationContext"
                rules="required"
                name="الخدمات"
              >
                <b-form-group label="الخدمات" label-for="servicesIds">
                  <b-form-select
                    id="servicesIds"
                    v-model="servicesIds"
                    :options="optionsServices"
                    multiple
                  ></b-form-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider name="ibanAttachment">
                <b-form-group label="صورة  اﻷيبان" label-for="ibanAttachment">
                  <b-form-file
                    id="ibanAttachment"
                    @change="handleFileChange($event, 'ibanAttachment')"
                    placeholder="Choose a file..."
                  ></b-form-file>
                </b-form-group>

                <b-row v-if="ibanAttachment">
                  <a :href="ibanAttachment" target="_blank">
                    <img
                      :src="ibanAttachment"
                      alt=""
                      width="60px"
                      height="90px"
                      class="avatar m-1"
                    />
                  </a>

                  <div>
                    <p v-if="ibanAttachmentMessage" class="m-1">
                      {{ ibanAttachmentMessage }}
                    </p>

                    <a
                      class="btn btn-sm btn-danger m-1"
                      @click="deleteImage(ibanAttachment, 'ibanAttachment')"
                      >حذف</a
                    >
                  </div>
                </b-row>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }}
            </b-button>
            <!-- spinner -->
            <b-button v-if="loading" variant="primary" disabled class="mr-1">
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              @click="hide"
            >
              إلغاء
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCardText,
  BMedia,
  BAvatar,
  BSpinner,
  BProgress,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { watch } from "@vue/composition-api";
import DataService from "./data/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import md5 from "js-md5";
import {
  ref as firebaseRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig";

// import { ibanRule } from "./ibanRule";

// Define the custom rule (if not already defined globally)
extend("ibanRule", {
  validate: (value) => {
    const ibanRegex = /^[SA]{2}[0-9]{16}$/;
    return ibanRegex.test(value);
  },
  message:
    "تنسيق IBAN غير صالح. يجب أن يكون مكونًا من حرفين كبيرين يبدأ   (SA) متبوعين بـ 16 رقمًا",
});

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BProgress,
    BFormSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    // providerData: {
    //   type: Object,
    //   deleteObject: () => {}
    // },
    optionsServices: {
      type: Array,
      required: true,
    },
    optionsCities: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    // isAddPasswordMode: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      headerType: "",
      isAddPasswordMode: false,
      // isAddNewUserSidebarActive: false,
      userName: "",
      userEmail: "",
      userPhone: "",
      name: "",
      password: "",
      email: "",
      phone: "",
      provider_name: "",
      description: "",
      cityId: "",
      servicesIds: [],
      registrationNumber: "",
      ibanNumber: "",
      ibanAttachment: "",
      registrationAttachment: "",
      id: "",
      loading: false,
      allerrors: [],

      providerData: {},

      servicesArray: [],

      image: "",
      file: null,
      progress: 0,
      registrationAttachmentProgress: 0,
      ibanAttachmentProgress: 0,
      imageUrl: "",
      message: "",
      ibanAttachmentMessage: "",
      registrationAttachmentMessage: "",
      bankAccountName: "",

      bankOptions: [
        { value: "1", text: "حساب باي موب" },
        { value: "2", text: "البنك اﻷهلي" },
      ],
      codeIban: ``,
    };
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      console.log("newVal:", val, "Type:", typeof val);
      if (typeof val !== "boolean") {
        throw new Error("input is invalid type");
      }

      if (!val) return;
      if (Object.entries(this.dataSlider).length === 0) {
        this.headerType = "إضافة";
        this.isAddPasswordMode = true;
        this.initValues();
      } else {
        this.headerType = "تعديل";
        this.isAddPasswordMode = false;

        // DataService.get(this.dataSlider.id)
        // .then(response => {
        //   this.providerData = response.data
        //   console.log(this.providerData)
        // })

        // console.log(this.dataSlider.cityId)

        this.id = this.dataSlider.id;
        this.userName = this.dataSlider.user.name;
        this.userEmail = this.dataSlider.user.email;
        this.userPhone = this.dataSlider.user.phone;
        this.name = this.dataSlider.name;
        this.image = this.dataSlider.image;
        this.imageUrl = this.dataSlider.image;
        this.description = this.dataSlider.description;
        (this.cityId = this.dataSlider.cityId),
          (this.servicesIds = this.dataSlider.services.map((item) => item.id)),
          (this.registrationNumber = this.dataSlider.registrationNumber),
          (this.ibanNumber = this.dataSlider.ibanNumber),
          (this.ibanAttachment = this.dataSlider.ibanAttachment),
          (this.registrationAttachment =
            this.dataSlider.registrationAttachment),
          (this.bankAccountName = this.dataSlider.bankAccountName);
        // this.accountType     = 2

        // this.initValues()
      }
    },
  },
  methods: {
    initValues() {
      if (this.dataSlider.id) return;
      this.id = null;
      this.name = null;
      // this.accountType = 2
      this.password = null;
      // this.phone    = null
      this.image = null;
      this.imageUrl = null;
      this.userName = null;
      this.userPhone = null;
      this.userEmail = null;
      this.name = null;
      this.description = null;
      (this.cityId = null),
        (this.servicesIds = []),
        (this.registrationNumber = null),
        (this.ibanNumber = null),
        (this.ibanAttachment = null),
        (this.registrationAttachment = null),
        (this.bankAccountName = null),
        (this.allerrors = []);
    },
    prepareData() {
      return {
        userName: this.userName,
        userPassword: md5(this.password),
        userPhone: this.userPhone,
        userEmail: this.userEmail,
        image: this.imageUrl,
        name: this.name,
        description: this.description,
        cityId: this.cityId,
        servicesIds: this.servicesIds,
        registrationNumber: this.registrationNumber,
        ibanNumber: this.ibanNumber,
        ibanAttachment: this.ibanAttachment,
        registrationAttachment: this.registrationAttachment,
        bankAccountName: this.bankAccountName,
      };
    },
    prepareEditData() {
      return {
        id: this.id,
        userName: this.userName,
        userEmail: this.userEmail,
        userPhone: this.userPhone,
        name: this.name,
        image: this.imageUrl,
        description: this.description,
        cityId: this.cityId,
        servicesIds: this.servicesIds,
        registrationNumber: this.registrationNumber,
        ibanNumber: this.ibanNumber,
        ibanAttachment: this.ibanAttachment,
        registrationAttachment: this.registrationAttachment,
        bankAccountName: this.bankAccountName,
      };
    },
    onSubmit() {
      if (!this.id) {
        this.sendData();
      } else {
        this.editData();
      }
    },
    sendData() {
      this.loading = true;
      this.allerrors = [];

      DataService.create(this.prepareData())
        .then((response) => {
          this.loading = false;
          // let responseCode = response.data.responseCode
          let responseCode = response.status;
          if (responseCode == 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: "EditIcon",
                variant: "success",
                text: response.data.responseDescription,
              },
            });
            this.$emit("update:is-add-new-user-sidebar-active", false);
            this.$emit("refreshData", response.data.data);
          } else if (responseCode === 101) {
            console.log("error");
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
    editData() {
      this.allerrors = [];
      this.loading = true;

      DataService.update(this.id, this.prepareEditData())
        .then((response) => {
          this.loading = false;
          // let responseCode = response.data.responseCode
          let responseCode = response.status;
          if (responseCode == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "مزودي الخدمات",
                icon: "EditIcon",
                variant: "success",
                text: "تم التعديل بنجاح",
              },
            });
            this.$emit("update:is-add-new-user-sidebar-active", false);

            DataService.getAll(this.currentPage).then((response) => {
              // this.getUserCount()
              this.items = response.data;
              this.$emit("refreshData", this.items);
            });
          } else if (responseCode == 101) {
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.image = file;
      this.imageUrl = URL.createObjectURL(file);
    },

    handleFileChange(event, folder) {
      // this.file = event.target.files[0];

      const file = event.target.files[0];

      if (file) {
        this.uploadToFirebase(file, folder);
      } else {
        alert("Please select a file first!");
        return;
      }
    },
    uploadToFirebase(file, folder) {
      console.log("Uploading" + folder);
      // Create a reference to Firebase Storage
      // const storageRef = firebaseRef(storage, `images/providers/${this.file.name}`);

      const storageRef = firebaseRef(storage, `images/${folder}/${file.name}`);

      // Start the upload
      // const uploadTask = uploadBytesResumable(storageRef, this.file);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Monitor upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = progress.toFixed(0); // Update progress
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          if (folder == "providers") {
            this.imageUrl = url; // Save the image URL
          }

          if (folder == "ibanAttachment") {
            this.ibanAttachment = url; // Save the image URL
          }

          if (folder == "registrationAttachment") {
            this.registrationAttachment = url; // Save the image URL
          }
        }
      );
    },
    async deleteImage(url, folder) {
      // if (!this.imageUrl) {
      //   this.message = "Please enter the image path.";
      //   return;
      // }

      // Create a reference to the file to delete
      // const fileRef = firebaseRef(storage, this.imageUrl);
      const fileRef = firebaseRef(storage, url);

      try {
        // Delete the file
        await deleteObject(fileRef);

        if (folder == "providers") {
          this.message = "Image deleted successfully.";
          this.imageUrl = ""; // Save the image URL
        }

        if (folder == "ibanAttachment") {
          this.ibanAttachmentMessage = "Image deleted successfully.";
          this.ibanAttachment = ""; // Save the image URL
        }

        if (folder == "registrationAttachment") {
          this.registrationAttachmentMessage = "Image deleted successfully.";
          this.registrationAttachment = ""; // Save the image URL
        }
      } catch (error) {
        console.error("Error deleting the image:", error);
        this.message = "Failed to delete the image.";
      }
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
    width: 50rem;
  }
}
</style>
