<template>
  <b-card-code title="مزود الخدمة">
    
    <!-- select 2 demo -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-orderProvider
        variant="outline-primary"
        class="btn-primary"
        
      >
        تعديل مزود الخدمة
      </b-button>


    <!-- select 2 demo -->
    <b-modal 
      @hidden="clearForm"
      hide-footer 
      ref="myModal"
      id="modal-orderProvider"
      title="تعديل مزود الخدمة"
      ok-title="submit"
      
      cancel-variant="danger"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >
      
      
        <b-form-group
          label="بحث بإسم مزود الخدمة"
          label-for="name"
        >
        
         
          <b-form-input
            id="search-select"
            v-model="searchQuery"
            
            @keyup="getProvider()"
          />
      
        </b-form-group>

        <b-form-group
          label="اختار مزود الخدمة بعد البحث"
          label-for="vue-select"
        >
          <!-- <v-select -->
          <b-form-select
            id="vue-select"
            v-model="providerId"
            :options="formattedOptions"
            
            />
            <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
        </b-form-group>


        <!-- <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required|integer"
          >
            <b-form-group
              label="phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Phone"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

              <span class="text-danger text-sm" v-if="allerrors.phone">{{ allerrors.phone[0] }}</span>

            </b-form-group>
          </validation-provider> -->

          
        
           <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  v-if="!loading"
                >
                  إرسال
                </b-button>
                <!-- spinner -->
                <b-button
                  v-if="loading"
                  variant="primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner small />
                  Loading...
                </b-button>
               
              </div>

        
      </b-form>
            
    </validation-observer>


    
          
    </b-modal>

   
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>

import {
  BLink,BCard,  BAvatar, BBadge, BOverlay,
  BRow, BCol,BSidebar, BForm, BFormTextarea,BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormFile, BCardText, BMedia, BSpinner,BFormSelect, BModal, VBModal, BAlert
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { watch } from '@vue/composition-api'
import DataService from '../data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



import BCardCode from '@core/components/b-card-code'

import { codeBasic } from './code'

export default {
  components: {
    BModal,
    BAlert,
    BCardCode,
    BLink,
    BCard, 
    BBadge, 
    BOverlay,
    BRow, 
    BCol,
    BSidebar,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    BFormSelect,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      codeBasic,

      loading:false,
      name: '',
      phone: '',
      providerId: '',
      description: '',
      isLoading: false,
      allerrors: [],

      selected: null,
      options: [],
      orderId:this.$route.params.id,

      searchQuery: "",

      selected_t: '',
      option_t: [],
    }
  },
  computed: {
    formattedOptions() {
      // const query = this.searchQuery.toLowerCase();
      // Map raw options to the format expected by b-form-select
      
      // this.getProvider(query);

      // .filter(option => option.name.toLowerCase().includes(query))
      return this.options
      .map(option => ({
        value: option.id,
        text: option.name,
      }));
    },
  },
  mounted() {
    // this.getProvider(keyword);
  },
  methods: {
    getProvider() {
      this.isLoading = true;

      const query = this.searchQuery;
      
      if (query.length > 3) {

        DataService.getProvider(query)
          .then(response => {
              this.options = response.data
              
          })

      }

      // return this.options
      // // .filter(option => option.name.toLowerCase().includes(query))
      // .map(option => ({
      //   value: option.id,
      //   text: option.name,
      // }));
    },

    prepareData(){
      return {
        orderId: this.orderId, 
        providerId: this.providerId,
      }
    },
    onSubmit(){
        this.sendData()
    },
    sendData(){
      this.loading = true;
      this.allerrors = []
      // this.$router.replace('policy')
      
      // console.log("this router");
      // console.log(this.prepareData());
        DataService.updateProvider(this.prepareData())
        .then(response => {
          this.loading = false
          // let responseCode = response.data.responseCode
          let responseCode = response.status
          if(responseCode == 200){
             

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: 'EditIcon',
                variant: 'success',
                text: "تم اﻹرسال بنجاح"
              },
            })

            this.$emit('refreshData',this.orderId)

            this.$refs.myModal.hide();
       
          }else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    clearForm() {
      // Reset form data when the modal is closed
      this.formData = {
        orderId: "",
        providerId: "",
      };
      console.log("Form cleared");
    },
  },
  setup() {
   
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


