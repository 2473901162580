<template>
    <b-card title="">

        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اﻹسم</span>
            </th>
            <td class="pb-50">
              {{ provider.name }}
            </td>
          </tr>

          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
                  <b-badge  :variant="status[1][provider.status]" style="cursor: pointer;">
                        {{ status[0][provider.status] }}
                  </b-badge>
            </td>
          </tr> -->

          <tr>
            <th>
              <feather-icon
                icon="TypeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الوصف</span>
            </th>
            <td>
              {{ provider.description }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="ActivityIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">التقييم</span>
            </th>
            <td>
              {{ provider.rating }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="HashIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المشاريع المكتملة</span>
            </th>
            <td>
              {{ provider.completedProjects }}
            </td>
          </tr>
          
        </table>
    </b-card>
</template>

<script>
import { BCard, BCardText, BBadge } from 'bootstrap-vue'
export default {
    components: {
        BCard,
        BCardText,
        BBadge
    },
    props : {
        provider : {
            type : Object,
            requied: true
        }
    },
    data() {
        return {
            status: [
                {
                1: 'Actvie', 0: 'Inactive',
                },
                {
                1: 'success', 0: 'danger', 
                }
            ],
        }
    },

}
</script>