<template>

  <b-card-code
    no-body
  >
    
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
  
        <add-new
          :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"    
          :dataSlider="sidebarData"  
          :adminType="adminType"
          @refreshData="refreshData"
        />

        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
        <!-- <b-button
            @click="addNewData"
            variant="primary"
            class="mb-2"
          >
            Add 
          </b-button> -->
          

          <b-table 
            responsive
            :items="items" 
            :fields="tableColumns" 
            striped 
            class="position-relative"
          >

          <template #cell(id)="data">
              {{data.index + 1}}
          </template>


  


          <template #cell(isActive)="data">
            <!-- @click="changeStatus(data.item.id)" -->
            <b-badge  :variant="isActive[1][data.value]" style="cursor: pointer;">
              {{ isActive[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(image)="data">
            <b-avatar :src="data.value" />
          </template>

          
          

          </b-table>


        

        </b-overlay>
      <!-- </div> -->
  </b-card-code>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton,BCardBody, BPagination  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'


export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BPagination,
  },

  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      adminType: [],
      pagination_per_page: 20,
      pagination_total: 100,

      currentPage: 1,
      perPage: 1,
      rows: 200,

      tableColumns: [
        { key: 'id',label: 'الرقم', sortable: true },
        { key: 'name', label: ' اﻹسم', sortable: true },
        { key: 'description', label: 'الوصف', sortable: true },
        { key: 'image', label: 'الصورة'},
        { key: 'isActive', label: 'الحالة'},
        
      ],
      isActive: [
        {
          false: 'غير مفعلة', true: 'مقعلة'
        },
        {
          false: 'danger', true: 'success'
        }
      ],
      
    }
  },

  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAll(page)
      .then(response => {
        
        this.isLoading = false
        // this.items = response.data.data
        this.items = response.data
        
        })
    },
    
   
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    }
  },
  mounted() {
    this.getData()
    // this.getType()
  },
  
}
</script>