<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-row>
      <b-col cols="12" lg="12">
        <b-card-code title="اﻹشعارات">
          <!-- BODY -->
          <validation-observer #default="{ handleSubmit }" ref="brnadForm">
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="initValues"
            >
              <!--  Name -->
              <validation-provider
                #default="validationContext"
                name="احتيار المستخدمين"
                rules="required"
              >
                <b-form-group label="احتيار المستخدمين" label-for="name">
                  <b-form-select v-model="selected" :options="options" />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.name">{{
                    allerrors.name[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <!--  title -->
              <validation-provider
                #default="validationContext"
                name="العنوان"
                rules="required"
              >
                <b-form-group label="العنوان" label-for="title">
                  <b-form-input
                    id="title"
                    v-model="title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="أكتب العنوان"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.title">{{
                    allerrors.title[0]
                  }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="الوصف"
                rules="required"
              >
                <b-form-group label="الوصف" label-for="description">
                  <b-form-textarea
                    v-model="description"
                    id="textarea-default"
                    placeholder="نص اﻹشعار"
                    rows="3"
                  />

                  <!-- <quill-editor
                    v-model="description"
                    :options="snowOption"
                  /> -->

                  <!-- <template #code>
                    {{ codeSnow }}
                  </template> -->

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span
                    class="text-danger text-sm"
                    v-if="allerrors.description"
                    >{{ allerrors.description[0] }}</span
                  >
                </b-form-group>
              </validation-provider>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  v-if="!loading"
                >
                  إرسال
                </b-button>
                <!-- spinner -->
                <b-button
                  v-if="loading"
                  variant="primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner small />
                  Loading...
                </b-button>
                <!-- end spinner -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="danger"
                  @click="hide"
                >
                  إلغاء
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card-code>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BOverlay,
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCardText,
  BMedia,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { watch } from "@vue/composition-api";
import DataService from "./data/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import md5 from "js-md5";
import {
  ref as firebaseRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig";

import { avatarText } from "@core/utils/filter";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { quillEditor } from "vue-quill-editor";
import { codeSnow } from "./code";

export default {
  components: {
    quillEditor,
    BCardCode,
    BLink,
    BCard,
    BBadge,
    BOverlay,
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BFormSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  data() {
    return {
      codeSnow,
      snowOption: {
        theme: "snow",
      },
      title: ``,
      description: ``,
      isLoading: false,
      allerrors: [],

      selected: null,
      options: [
        { value: "0", text: "العملاء" },
        { value: "1", text: "مزودي الخدمات" },
      ],
    };
  },
  methods: {
    prepareData() {
      return {
        accountType: this.selected,
        title: this.title,
        body: this.description,
      };
    },
    onSubmit() {
      this.sendData();
    },
    sendData() {
      this.loading = true;
      this.allerrors = [];
      // this.$router.replace('policy')

      // console.log("this router");
      // console.log(this.$router);
      DataService.send(this.prepareData())
        .then((response) => {
          this.loading = false;
          // let responseCode = response.data.responseCode
          let responseCode = response.status;
          if (responseCode == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "EditIcon",
                variant: "success",
                text: "تم اﻹرسال بنجاح",
              },
            });
            // this.loading = true;
            this.$router.replace({ name: "/" });
          } else if (responseCode === 101) {
            console.log("error");
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
    onSubmit() {
      if (!this.id) {
        this.sendData();
      } else {
        this.editData();
      }
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
    };
  },
  mounted() {},
};
</script>

<style scoped>
/* Force LTR direction for this container */
.ltr-container {
  direction: ltr;
  text-align: left;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
