<template>
    <b-card title="من العنوان">
        <table class="mt-2 mt-xl-0 w-100">

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الحي</span>
            </th>
            <td class="pb-50">
               {{fromLocation.district.name}} 
            </td>

            <th class="pb-50">
              <feather-icon
                icon="MapIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المدينة</span>
            </th>
            <td class="pb-50">
               {{fromLocation.district.cityName}}
            </td>


            <th class="pb-50">
              <feather-icon
                icon="HashIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الطابق</span>
            </th>

            <td class="pb-50 text-capitalize">
              {{ fromLocation.floor }}
            </td>

          </tr>
          <tr>
            

            <th class="pb-50">
              <feather-icon
                icon="TypeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الملاحظة</span>
            </th>
            <td class="pb-50" colspan="5">
               {{fromLocation.note}}
            </td>
            
          </tr>

        </table>
    </b-card>
</template>


<script>
import { BCard, BBadge } from 'bootstrap-vue'

export default {
    components : {
        BCard,
        BBadge
    },
    props : {
      fromLocation: {
        type: [Object, Array],
        required: true,
      },
     
    },
    data() {
        return {
        }
    },
    methods: {
     
    },
    mounted() {
      
    },
}
</script>