<template>
  <b-card-code no-body>
    <!-- <div class="d-flex justify-content-between  flex-wrap"> -->

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"
      :dataSlider="sidebarData"
      @refreshData="refreshData"
    />

    <b-overlay :show="isLoading" rounded="sm">
      <b-button @click="addNewData" variant="primary" class="mb-2">
        إضافة
      </b-button>

      <b-table
        responsive
        :items="items"
        :fields="tableColumns"
        striped
        class="position-relative"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="deleteData(data.item.period)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-overlay>
    <!-- </div> -->
  </b-card-code>
</template>

<script>
import {
  BTable,
  BCard,
  BCardText,
  BAvatar,
  BOverlay,
  BBadge,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BCardBody,
  BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddNew from "./AddNew.vue";
// import companyData from './companyData'
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import { onMounted } from "vue";
import DataService from "./data/services";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Swal from "sweetalert2";

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BPagination,
  },

  data() {
    return {
      items: [],
      sidebarData: {},

      isAddPasswordMode: false,

      isAddNewSidebarActive: false,
      isLoading: true,
      pagination_per_page: 20,
      pagination_total: 100,

      currentPage: 1,
      perPage: 1,
      rows: 200,

      tableColumns: [
        { key: "id", label: "الرقم", sortable: true },
        // { key: 'key', label: 'الاسم ', sortable: true },
        { key: "period", label: "الفترة الزمنية", sortable: true },

        { key: "actions", label: " اﻹجراءات" },
      ],
    };
  },
  methods: {
    getData() {
      var arr_items = [];
      var arr_data = [];

      this.isLoading = true;
      DataService.getAll().then((response) => {
        // console.log(response.data)

        for (const [key, value] of Object.entries(response.data)) {
          // console.log(`Key: ${key}, Value: ${value}`);
          var obj = {};
          // obj['key'] = `${key}`;
          obj["period"] = `${value}`;

          arr_data.push(obj);
        }

        // arr_items.push(response.data);

        this.isLoading = false;
        // this.items = response.data.data
        // this.items = arr_items
        this.items = arr_data;
        // console.log(this.items);
      });
    },

    async deleteData(id) {
      const result = await Swal.fire({
        title: "هل أنت متأكد ؟",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!",
      });

      if (result.isConfirmed) {
        this.isLoading = true;
        console.log(id);
        DataService.delete(id).then((response) => {
          this.isLoading = false;

          var arr_data = [];
          DataService.getAll().then((response) => {
            for (const [key, value] of Object.entries(response.data)) {
              // console.log(`Key: ${key}, Value: ${value}`);
              var obj = {};
              // obj['key'] = `${key}`;
              obj["period"] = `${value}`;

              arr_data.push(obj);
            }

            this.isLoading = false;
            this.items = arr_data;
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "successfully",
              icon: "BellIcon",
              text: "تم الحذف بنجاح",
              variant: "success",
            },
          });
        });
      }
    },

    addNewData() {
      this.isAddPasswordMode = true;
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editData(data) {
      // console.log(data)
      this.isAddPasswordMode = false;
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.isAddNewSidebarActive = val;
    },
    refreshData(newData) {
      this.items = newData;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
