import axios from '@/libs/axios'

class DataService {
  getAll(page,userId) {
    return axios.get("/wallet/admin/?page="+page+"&userId="+userId);
  }
  get(id) {
    return axios.get(`/users/${id}`);
  }
  create(data) {
    // return axios.post("/auth/admin/signup", data);
    return axios.post(`/wallet/admin/?amount=${data.amount}&userId=${data.userId}&description=${data.description}`);
  }
  update(id, data) {
    // return axios.put(`/users/${id}`, data);
    return axios.put(`/users`, data);
  }
  delete(id) {
    return axios.delete(`/users/${id}`);
  }
  changePassword(id) {
    return axios.get(`/changePassword/${id}`);
  }
  getUserCount() {
    // return axios.get(`/users/count?accountType=2`);
    return axios.get("/users/count?accountType=2");

  }
}
export default new DataService();