<template>
  <b-card-code no-body>
    <!-- <div class="d-flex justify-content-between  flex-wrap"> -->

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"
      :dataSlider="sidebarData"
      :adminType="adminType"
      :optionsServices="optionsServices"
      :optionsCities="optionsCities"
      :currentPage="currentPage"
      @refreshData="refreshData"
    />

    <b-overlay :show="isLoading" rounded="sm">
      <b-button @click="addNewData" variant="primary" class="mb-2">
        إضافة
      </b-button>

      <b-table
        responsive
        :items="items"
        :fields="tableColumns"
        striped
        class="position-relative"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(image)="data">
          <b-avatar :src="data.value" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item> -->

            <b-dropdown-item @click="editData(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'provider-Profile', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">التفاصيل</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'walletProvider', params: { id: data.item.userId } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">المحفظة</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'review', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">المراجعات</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          :total-rows="pagination_total"
          :per-page="pagination_per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value) => getData(value)"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-overlay>
    <!-- </div> -->
  </b-card-code>
</template>

<script>
import {
  BTable,
  BCard,
  BCardText,
  BAvatar,
  BOverlay,
  BBadge,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BCardBody,
  BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddNew from "./AddNew.vue";

import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import { onMounted } from "vue";
import DataService from "./data/services";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Swal from "sweetalert2";

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BPagination,
  },

  data() {
    return {
      items: [],
      providerData: {},
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      adminType: [],
      optionsServices: [],
      optionsCities: [],
      toast: null,
      pagination_per_page: 20,
      pagination_total: 100,

      currentPage: 1,
      perPage: 1,
      rows: 200,

      tableColumns: [
        { key: "id", label: "الرقم", sortable: true },
        { key: "name", label: " اﻹسم", sortable: true },
        { key: "image", label: "الصورة", sortable: true },
        // { key: 'description', label: 'الوصف'},
        { key: "rating", label: "التقييم" },
        { key: "reviewsCount", label: "عدد التقييمات" },
        { key: "completedProjects", label: "المشاريع المكتملة" },

        { key: "actions", label: " اﻹجراءات" },
      ],
    };
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      DataService.getAll(page).then((response) => {
        this.getUserCount();
        this.isLoading = false;
        // this.items = response.data.data
        this.items = response.data;
        this.currentPage = page;
      });
    },
    getUserCount() {
      DataService.getUserCount().then((response) => {
        this.pagination_per_page = 20;
        // this.pagination_total = 40;
        this.pagination_total = response.data.items;
      });
    },
    getServices() {
      DataService.getServices().then((response) => {
        this.optionsServices = response.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      });
    },
    getCities() {
      DataService.getCities().then((response) => {
        this.optionsCities = response.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      });
    },
    reviewData() {
      this.isLoading = true;
      DataService.reviewData(providerId, page).then((response) => {
        this.isLoading = false;
        this.items = response.data.data;
      });
    },
    get(providerId) {
      this.isLoading = true;
      DataService.get(providerId).then((response) => {
        this.providerData = response.data;
      });
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editData(data) {
      DataService.get(data.id).then((response) => {
        this.sidebarData = response.data;
      });
      // this.sidebarData = data
      setTimeout(() => this.toggleDataSidebar(true), 1000);
    },
    toggleDataSidebar(val = false) {
      this.isAddNewSidebarActive = val;
    },
    refreshData(newData) {
      this.items = newData;
    },
  },
  mounted() {
    this.getData(this.currentPage);
    this.getServices();
    this.getCities();
  },
};
</script>

<style scoped>
.sidebar-width {
  width: 50% !important;
}

.sidebar-maxlg {
  width: 50% !important;
}
</style>
