var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',[_c('label',[_vm._v("إبحث بﻹسم عن العميل")]),_c('validation-provider',{attrs:{"rules":"required","name":"اسم العميل"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"search for customer","id":"search-select"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-button',{staticClass:"mb-2",staticStyle:{"margin":"45px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getCustomer()}}},[_vm._v(" بحث ")])],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"العميل","label-for":"fromCustomer"}},[_c('validation-provider',{attrs:{"rules":"required","name":"العميل"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"fromCustomer","options":_vm.formattedOptions},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"التاريخ","label-for":"preferredDate"}},[_c('validation-provider',{attrs:{"rules":"required","name":"التاريخ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"preferredDate"},model:{value:(_vm.preferredDate),callback:function ($$v) {_vm.preferredDate=$$v},expression:"preferredDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"الخدمة","label-for":"service"}},[_c('validation-provider',{attrs:{"rules":"required","name":"الخدمة"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"service","options":_vm.servicesOptions},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"الزمن","label-for":"preferredTime"}},[_c('validation-provider',{attrs:{"rules":"required","name":"الزمن"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"preferredTime","options":_vm.periodsOptions},model:{value:(_vm.preferredTime),callback:function ($$v) {_vm.preferredTime=$$v},expression:"preferredTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","lg":"6"}},[_c('b-card',{staticClass:"mb-3"},[_c('h5',[_vm._v("من العنوان")]),_c('b-form-group',{attrs:{"label":"المدينة","label-for":"fromCity"}},[_c('validation-provider',{attrs:{"rules":"required","name":"1 المدينة"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"fromCity","options":_vm.citiesOptions},on:{"change":function($event){_vm.onCityChange('from'), _vm.areaOptions()}},model:{value:(_vm.fromAddress.district.cityName),callback:function ($$v) {_vm.$set(_vm.fromAddress.district, "cityName", $$v)},expression:"fromAddress.district.cityName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"الحي","label-for":"fromArea"}},[_c('validation-provider',{attrs:{"rules":"required","name":"1 الحي"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"fromArea","options":_vm.filteredItems},model:{value:(_vm.fromAddress.district.name),callback:function ($$v) {_vm.$set(_vm.fromAddress.district, "name", $$v)},expression:"fromAddress.district.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('leaflet-map',{attrs:{"type":'from'},on:{"location-selected":_vm.updateLocation}}),_c('b-form-group',{attrs:{"label":"احداثيات الموقع","label-for":"from location"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('validation-provider',{attrs:{"rules":"required","name":"1 خط العرض"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fromlat","type":"text"},model:{value:(_vm.fromAddress.lat),callback:function ($$v) {_vm.$set(_vm.fromAddress, "lat", $$v)},expression:"fromAddress.lat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"mr-2",attrs:{"rules":"required","name":"1 خط الطول"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fromlng","type":"text"},model:{value:(_vm.fromAddress.lng),callback:function ($$v) {_vm.$set(_vm.fromAddress, "lng", $$v)},expression:"fromAddress.lng"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',{attrs:{"label":"الطابق","label-for":"fromFloor"}},[_c('validation-provider',{attrs:{"rules":"required","name":"1 الطابق"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fromFloor","type":"number"},model:{value:(_vm.fromAddress.floor),callback:function ($$v) {_vm.$set(_vm.fromAddress, "floor", $$v)},expression:"fromAddress.floor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ملاحظة","label-for":"fromNote"}},[_c('validation-provider',{attrs:{"rules":"","name":"ملاحظة"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"fromNote"},model:{value:(_vm.fromAddress.note),callback:function ($$v) {_vm.$set(_vm.fromAddress, "note", $$v)},expression:"fromAddress.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"6","lg":"6"}},[_c('b-card',{staticClass:"mb-3"},[_c('h5',[_vm._v("إلى العنوان")]),_c('b-form-group',{attrs:{"label":"المدينة","label-for":"toCity"}},[_c('validation-provider',{attrs:{"rules":"required","name":"المدينة"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"toCity","options":_vm.citiesOptions},on:{"change":function($event){_vm.onCityChange('to'), _vm.areaOptionsTo()}},model:{value:(_vm.toAddress.district.cityName),callback:function ($$v) {_vm.$set(_vm.toAddress.district, "cityName", $$v)},expression:"toAddress.district.cityName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"الحي","label-for":"toArea"}},[_c('validation-provider',{attrs:{"rules":"required","name":"الحي"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"toArea","options":_vm.filteredItemsTo},model:{value:(_vm.toAddress.district.name),callback:function ($$v) {_vm.$set(_vm.toAddress.district, "name", $$v)},expression:"toAddress.district.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('leaflet-map',{attrs:{"type":'to'},on:{"location-selected":_vm.updateLocation}}),_c('b-form-group',{attrs:{"label":"إحداثيات الموقع","label-for":"toFloor"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('validation-provider',{attrs:{"rules":"required","name":"خط العرض"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tolat","type":"text"},model:{value:(_vm.toAddress.lat),callback:function ($$v) {_vm.$set(_vm.toAddress, "lat", $$v)},expression:"toAddress.lat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"mr-2",attrs:{"rules":"required","name":"خط الطول"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tolng","type":"text"},model:{value:(_vm.toAddress.lng),callback:function ($$v) {_vm.$set(_vm.toAddress, "lng", $$v)},expression:"toAddress.lng"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',{attrs:{"label":"الطابق","label-for":"toFloor"}},[_c('validation-provider',{attrs:{"rules":"required","name":"الطابق"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"toFloor","type":"number"},model:{value:(_vm.toAddress.floor),callback:function ($$v) {_vm.$set(_vm.toAddress, "floor", $$v)},expression:"toAddress.floor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ملاحظة","label-for":"toNote"}},[_c('validation-provider',{attrs:{"rules":"","name":"ملاحظة"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"toNote"},model:{value:(_vm.toAddress.note),callback:function ($$v) {_vm.$set(_vm.toAddress, "note", $$v)},expression:"toAddress.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","lg":"6"}},[_c('b-card',{staticClass:"mb-3"},[_c('h5',[_vm._v("العناصر")]),_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"d-flex mb-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"اسم العنصر"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"اسم العنصر"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"العدد"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"ml-2",attrs:{"type":"number","placeholder":"العدد"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_vm._v(" Remove ")])],1)}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addItem}},[_vm._v("Add Item")])],2)],1),_c('b-col',{attrs:{"cols":"6","lg":"6"}},[_c('b-card',{staticClass:"mb-3"},[_c('h5',[_vm._v("الصور")]),_c('div',[_c('input',{attrs:{"type":"file","multiple":""},on:{"change":_vm.handleFileChange}}),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary"},on:{"click":_vm.uploadFiles}},[_vm._v("رفع الصور")]),_c('ul',_vm._l((_vm.uploadedUrls),function(url,index){return _c('li',{key:index,attrs:{"id":index}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":url,"alt":"","width":"100px","height":"150px"}}),(url)?_c('a',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteImage(url, index)}}},[_vm._v("حذف الصورة")]):_vm._e()])])}),0)],1)])],1)],1),_c('b-form-group',{attrs:{"label":"الملاحظات","label-for":"note"}},[_c('validation-provider',{attrs:{"rules":"","name":"الملاحظات"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"success"}},[_vm._v("إنشاء الطلب")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }