<template>
    <div>
         <!-- Button to open modal -->
      <b-button variant="primary" @click="showModal">اختيار الموقع</b-button>
  
  <!-- Modal -->

        <b-modal
      ref="locationModal"
      title="اختيار الموقع"
      size="lg"
      hide-footer
      @shown="initializeMap"
    >
      <div>
        <!-- Map Section -->
        <div class="map-wrapper">
          <l-map
            ref="leafletMap"
            class="map-container"
            :zoom="zoom"
            :center="center"
            @click="onMapClick"
          >
            <l-tile-layer :url="osmUrl" :attribution="attribution" />
            <l-marker :lat-lng="markerPosition" :icon="customIcon" />
          </l-map>
        </div>
  
        <!-- Search Section -->
        <div class="controls mt-3">
          <input
            v-model="searchQuery"
            @input="onSearch"
            type="text"
            placeholder="ابحث عن الموقع..."
            class="form-control"
          />
          <ul v-if="searchResults.length" class="list-group mt-2">
            <li
              v-for="(result, index) in searchResults"
              :key="index"
              @click="selectLocation(result)"
              class="list-group-item"
            >
              {{ result.display_name }}
            </li>
          </ul>
        </div>
  
        <!-- Location Details -->
        <div v-if="locationDetails" class="details mt-3">
          <p><strong>العنوان:</strong> {{ locationDetails.display_name }}</p>
          <p><strong>خط العرض:</strong> {{ locationDetails.lat }}</p>
          <p><strong>خط الطول:</strong> {{ locationDetails.lon }}</p>
        </div>
      </div>
  
      <template>
        <b-button variant="secondary" @click="hideModal">إلغاء</b-button>
        <b-button variant="success" @click="confirmLocation(type)">تأكيد</b-button>
      </template>
    </b-modal>
    </div>
</template>
  
  <script>
  import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import {
  BRow, BCol,BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,BModal
} from 'bootstrap-vue'

  export default {
    props: {
        value: Object, // v-model binding for location
        type: {
            type: String,
            required: true,
        },
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      BRow, BCol,BSidebar, BForm, BFormGroup, BFormInput, 
      BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,BModal,
    },
    data() {
      return {
        zoom: 12,
        center: { lat: 21.5504432, lng: 39.1742363 }, // Default location
        osmUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        searchQuery: "",
        searchResults: [],
        markerPosition: { lat: 21.5504432, lng: 39.1742363 },
        locationDetails: null,
        customIcon: L.divIcon({
          className: "custom-pill-icon",
          html: `<div class="pill-marker">📍</div>`,
          iconSize: [60, 30],
          iconAnchor: [30, 15],
        }),
      };
    },
    emits: {
      // "location-selected": (locationData) => true,
      "location-selected": null,
    },
    methods: {
      showModal() {
        this.$refs.locationModal.show();
      },
      hideModal() {
        this.$refs.locationModal.hide();
      },
      confirmLocation(type) {
        const locationData = {
            type: this.type, // Pass the type ('from' or 'to')
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
            address: this.locationDetails ? this.locationDetails.display_name : "",
        };
        // console.log(locationData);
        this.$emit("location-selected", locationData);
        this.hideModal();
      },
      async onSearch() {
        if (!this.searchQuery.trim()) {
          this.searchResults = [];
          return;
        }
  
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            this.searchQuery
          )}&format=json`
        );
        this.searchResults = await response.json();
      },
      selectLocation(location) {
        const lat = parseFloat(location.lat);
        const lon = parseFloat(location.lon);
        this.center = { lat, lng: lon };
        this.markerPosition = { lat, lng: lon };
        this.locationDetails = location;
        this.searchResults = [];
      },
      async onMapClick(event) {
        const { lat, lng } = event.latlng;
        this.markerPosition = { lat, lng };
  
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
        );
        this.locationDetails = await response.json();
      },
      initializeMap() {
        if (this.$refs.leafletMap) {
          setTimeout(() => {
            this.$refs.leafletMap.mapObject.invalidateSize();
          }, 200); // Delay ensures the modal is fully rendered
        }
      },
    },
  };
  </script>
  
  <style>
  .map-wrapper {
    position: relative;
    height: 400px;
    width: 100%;
  }
  
  .map-container {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .list-group-item {
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .list-group-item:hover {
    background-color: #f5f5f5;
  }
  
  .details p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .pill-marker {
    background-color: #ffc436;
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
    width: 60px;
    height: 30px;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);
  }
  </style>
  
<style>
.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
	right: 0 !important;
  left: unset;
	}
</style>
